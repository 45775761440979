import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const LoadingScreen = () => {
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justifyContent="center"
      alignItems="center"
      bg="white"
      zIndex="1000"
      direction={"column"}
    >
      <Spinner size="xl" color="var(--brand)" />
    </Flex>
  );
};

export default LoadingScreen;
