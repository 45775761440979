import { Flex, IconButton } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import logo from "./img/guddenApp2.svg";
import styles from "./shop/shop.module.css";
import { useAuth } from "./shop/context/AuthContext";
import UserProfile from "./shop/components/account/UserProfile";
import { X } from "lucide-react";


function GuddenLayout({ children }) {

  const { currentUser } = useAuth();
  const restaurant = sessionStorage.getItem('restaurantId') || '/';

  return (
    <>  
      
      <Flex as="header" zIndex={9999999}>
        <div className={styles.container}>
          <Link className={styles.logo} title="Back to home">
            <img src={logo} alt="Logo" width={"62px"} />
          </Link>

          

          <Flex direction="row" gap={2} className={styles.right}>
            {/* <LanguageSwitcher
            restaurantId={restaurantId}
            selectedLanguage={selectedLanguage}
            defaultLanguage={defaultLanguage}
            secondaryLanguages={secondaryLanguages}
            onLanguageChange={handleLanguageChange} 
            styles={styles}
          />
        */}
        
          {currentUser &&
            <UserProfile />
          }
          <IconButton
          rounded={"full"}
          variant={"outline"}
            icon={<X /> }
            as={Link}
            to={`/${restaurant}/shop`}>
          </IconButton>
          </Flex>
        </div>
      </Flex>

      <Flex>{children}</Flex>
    </>
  );
}
export default GuddenLayout;
