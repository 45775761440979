import { useShopContext } from "../../context/ShopContext";
import { CircleHelp, CirclePlus, CircleMinus } from "lucide-react";
import { Flex, Box, Tooltip } from "@chakra-ui/react";
import useCart from "../../hooks/useCart";

const CartContent = () => {
  const {
    cartItems,
    calculateSubtotal,
    selectedLanguage,
    defaultLanguage,
    shopSettings,
    deliveryMode,
    styles,
    tip,
    setTip,
    serviceFeeTTC,
    deliveryFee,
  } = useShopContext();

  const { updateItemQuantity, calculateMinimumOrderAmountDifference } = useCart();

  const maxItemsPerOrder = shopSettings?.general?.maxItemsPerOrder || Infinity;
  const totalItemsInCart = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const increaseTip = () => {
    setTip((prevTip) => prevTip + 1);
  };
  const decreaseTip = () => {
    setTip((prevTip) => Math.max(0, prevTip - 1)); // Empêche le pourboire d'être négatif
  };

  const differenceToReachMinimum = calculateMinimumOrderAmountDifference();
  const minimumOrderAmount =
    deliveryMode === "delivery"
      ? shopSettings.delivery.minimumOrderAmount
      : shopSettings.takeAway.minimumOrderAmount;

  return (
    <>
      <ul className={styles.cartList}>
        {cartItems.length > 0 ? (
          cartItems.map((item, index) => (
            <li key={index} className={styles.cartItem}>
              {item.photo && (
                <div className={styles.itemImage}>
                  <img
                    src={item.photo}
                    alt={
                      item[`name_${selectedLanguage}`] ||
                      item[`name_${defaultLanguage}`]
                    }
                  />
                </div>
              )}

              <div className={styles.itemDetails}>
                <div className={styles.cartItemTitle}>
                  <div className={styles.itemName}>
                    {item[`name_${selectedLanguage}`] ||
                      item[`name_${defaultLanguage}`]}
                    {item.variation && <span> - {item.variation.name}</span>}
                  </div>

                  <div className={styles.itemPrice}>
                    {(item.quantity * parseFloat(item.price.replace(",", ".")))
                      .toFixed(2)
                      .replace(".", ",")}
                    €
                  </div>
                </div>

                {(item.extras?.length > 0 || item.note) && (
                  <Flex className={styles.cartItemInfo} direction={"column"}>
                    {item.extras && item.extras.length > 0 && (
                      <div className={styles.extrasList}>
                        <h6>Extras</h6>
                        {item.extras.map((extra, extraIndex) => (
                          <span key={extraIndex} className={styles.extra}>
                            {extra.name[selectedLanguage]}
                            {extraIndex < item.extras.length - 1 && (
                              <span className={styles.separator}> - </span>
                            )}
                          </span>
                        ))}
                      </div>
                    )}

                    {item.note && (
                      <div className={styles.extrasList}>
                        <h6>Note</h6>
                        <span>{item.note}</span>
                      </div>
                    )}
                  </Flex>
                )}
                <div className={styles.qty}>
                  <CircleMinus
                    size={24}
                    color="var(--restaurantColor)"
                    onClick={() =>
                      updateItemQuantity(
                        item.itemId,
                        item.variation?.name,
                        item.quantity - 1,
                        item.note,
                        item.extras
                      )
                    }
                  />

                  <Box className={styles.qtyNumber} fontWeight={500}>
                    {item.quantity}
                  </Box>

                  <Tooltip
                    label={`You cannot add more than ${maxItemsPerOrder} items to your order.`}
                    isDisabled={totalItemsInCart < maxItemsPerOrder}
                    p={3}
                    fontSize={15}
                    borderRadius={4}>
                    <CirclePlus
                      size={24}
                      color="var(--restaurantColor)"
                      onClick={() =>
                        updateItemQuantity(
                          item.itemId,
                          item.variation?.name,
                          item.quantity + 1,
                          item.note,
                          item.extras
                        )
                      }
                      // Désactiver le bouton si la limite d'articles est atteinte
                      disabled={totalItemsInCart >= maxItemsPerOrder}
                      style={{
                        cursor:
                          totalItemsInCart >= maxItemsPerOrder
                            ? "not-allowed"
                            : "pointer",
                        opacity:
                          totalItemsInCart >= maxItemsPerOrder
                            ? "0.24"
                            : "0.75",
                      }}
                      onMouseEnter={(e) => {
                        if (totalItemsInCart < maxItemsPerOrder) {
                          e.target.style.opacity = "1";
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (totalItemsInCart < maxItemsPerOrder) {
                          e.target.style.opacity = "0.75";
                        }
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </li>
          ))
        ) : (
          <p>Your bag is empty.</p>
        )}
      </ul>

      {cartItems.length > 0 && (
        <>
          <ul className={styles.cartFooter}>
            <li>
              <label>Subtotal</label>
              <div className={styles.itemPrice}>
                {calculateSubtotal().replace(".", ",")}€
              </div>
            </li>
            <li>
              <label>
                Tip
                <Tooltip
                  label="The tips that you generously provide are distributed monthly among the employees of the restaurant."
                  p={3}
                  fontSize={15}
                  borderRadius={4}>
                  <CircleHelp color="var(--grey500)" size={16} />
                </Tooltip>
              </label>
              <div className={styles.qty}>
                <CircleMinus
                  size={20}
                  color="var(--restaurantColor)"
                  onClick={decreaseTip}
                />
                <CirclePlus
                  size={20}
                  color="var(--restaurantColor)"
                  onClick={increaseTip}
                />
                <div className={styles.itemPrice}>{`${tip},00€`}</div>
              </div>
            </li>

            {deliveryMode === "delivery" && (
              <li>
                <label>Delivery fee</label>
                <div className={styles.itemPrice}>
                  {deliveryFee > 0
                    ? `${deliveryFee.toFixed(2).replace(".", ",")}€`
                    : "Free"}
                </div>
              </li>
            )}

            {serviceFeeTTC > 0 && (
              <li>
                <label>
                  Service fee
                  <Tooltip
                    label="This allows us to enhance your experience on our website and develop the mobile app."
                    p={3}
                    fontSize={15}
                    borderRadius={4}>
                    <CircleHelp color="var(--grey500)" size={16} />
                  </Tooltip>
                </label>
                <div className={styles.itemPrice}>
                  {serviceFeeTTC.toFixed(2).replace(".", ",")}€
                </div>
              </li>
            )}
          </ul>
          {differenceToReachMinimum > 0 && (
            <Box
              p={4}
              fontWeight={"500"}
              color={"var(--grey700)"}
              bg={"var(--error50)"}
              borderLeft={"2px solid var(--error)"}
              borderRadius={"8px"}>
              Just {differenceToReachMinimum.toFixed(2).replace(".", ",")}€ more
              to reach the {minimumOrderAmount.replace(".", ",")}€ minimum order
              amount for {deliveryMode}.
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CartContent;
