import React from "react";
import { useParams } from "react-router-dom";
import { ShopProvider } from "./context/ShopContext";
import AuthStateHandler from "./context/AuthStateHandler.js";

const MainProvider = ({ children }) => {
  const { restaurantId } = useParams();

  

  return (
    <ShopProvider restaurantId={restaurantId}>
      <AuthStateHandler restaurantId={restaurantId} />

        {React.cloneElement(children, { restaurantId })}
        
    </ShopProvider>
  );
};

export default MainProvider;
