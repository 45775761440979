import { useShopContext } from '../context/ShopContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

const useStripe = () => {
   
    const { restaurantId, setStripeKeys } = useShopContext();

    

    const loadStripeKeys = async () => {
      if (restaurantId) {
        const docRef = doc(db, `stripeConfigs/${restaurantId}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setStripeKeys(docSnap.data());
        } else {
          console.log("Aucune configuration Stripe trouvée.");
        }
      }
    };


    


    return { 
    
      loadStripeKeys

     
     };
};

export default useStripe;
