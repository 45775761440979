import { Flex, Text, Image } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { ReceiptText, User } from "lucide-react";
import { useAuth } from "../../context/AuthContext";
import { ToastContainer } from "react-toastify";
import { useState } from "react";

function AccountLayout({ children }) {

  const { currentUser } = useAuth();
  const [imageError, setImageError] = useState(false);

  return (
<>
    <ToastContainer
    autoClose={3000}
    hideProgressBar={true}
    closeOnClick
    theme="colored"
    position="top-center"
/>

    <Flex
      flex={1}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
      w={"100%"}>
      <Flex
        direction={"column"}
        padding={{ desktop: 12, mobile: 8 }}
        pb={{ desktop: 8, mobile: 8 }}
        pt={{ desktop: 4, mobile: 4 }}
        flex={1}
        gap={{ desktop: 16, mobile: 8 }}
        w={"100%"}
        maxW="1280px">
          
          <Flex direction={"row"} gap={4} alignItems={"center"}>



            {currentUser?.photoURL && !imageError ? (
              <Image
                src={currentUser.photoURL}
                alt="Profile"
                width="72px"
                height="72px"
                borderRadius={"50%"}
                onError={() => setImageError(true)}
              />
            ) : (
               <Flex w={"72px"} h={"72px"} borderRadius={"72px"} bg={"var(--grey100)"} justifyContent={"center"} alignItems={"center"}><User size={32} color="var(--grey500)" /></Flex>
            )}


          <Flex direction={"column"} gap={1} flex={1}>
            <h1>Welcome {currentUser?.firstName}!</h1>
            <Text color="#445b78">On this page, you can manage your account and follow your orders.</Text>
          </Flex></Flex>
          <Flex direction={"column"}
          gap={8}>
          <Flex
              className="tabLinks"
              w={"100%"}
              borderBottom={"1px solid var(--borders)"}
              direction={"row"}
              position={"sticky"}
              top="0"
              bg={"white"}
              zIndex={2}
              gap={2}
              mx={{ desktop: 0, mobile: -8 }}
              px={{ desktop: 0, mobile: 4 }}>
              <NavLink
                to={`/account/orders`}
                className="tabLink"
                style={({ isActive }) => ({
                  color: isActive ? "var(--brand)" : "var(--grey700)",
                })}>
                {({ isActive }) => (
                  <Flex alignItems={"center"} gap={1}>
                    <ReceiptText
                      size={16}
                      color={isActive ? "var(--brand)" : "var(--grey500)"}
                    />
                    <Text
                      color={isActive ? "var(--brand)" : "var(--grey500)"}
                      _hover={{ color: "var(--grey700)" }}>
                      Orders
                    </Text>
                  </Flex>
                )}
              </NavLink>
              <NavLink
                className="tabLink"
                to={`/account`}
                end
                style={({ isActive }) => ({
                  color: isActive ? "var(--brand)" : "var(--grey700)",
                })}>
                {({ isActive }) => (
                  <Flex alignItems={"center"} gap={1}>
                    <User
                      size={16}
                      color={isActive ? "var(--brand)" : "var(--grey500)"}
                    />
                    <Text
                      color={isActive ? "var(--brand)" : "var(--grey500)"}
                      _hover={{ color: "var(--grey700)" }}>
                      Account details
                    </Text>
                  </Flex>
                )}
              </NavLink>
              
            </Flex>

            {children}
          </Flex>
        </Flex>
    </Flex></>
  );
}
export default AccountLayout;
