import { useEffect, useState } from "react";
import Modal from "react-modal";
import { db } from "../../../firebaseConfig";
import { doc, updateDoc } from "firebase/firestore"; 
import { useAuth } from "../../context/AuthContext";
import { Flex, Stack, FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    height: "100%",
    width: "100%",
    maxWidth: "480px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99",
  },
};

const UserDetails = ({ currentUser, isOpen, onClose }) => {
  const { updateUserDetails, firstName, setFirstName, lastName, setLastName, phone, setPhone } = useAuth();
  const restaurant = sessionStorage.getItem('restaurantId') || '/';

  // Utiliser un drapeau pour éviter la réinitialisation des champs
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (currentUser && !initialized) {
      setFirstName(currentUser.firstName || "");
      setLastName(currentUser.lastName || "");
      setPhone(currentUser.phone || "");
      setInitialized(true); // Le drapeau est activé après la première initialisation
    }
  }, [currentUser, initialized, setFirstName, setLastName, setPhone]);

  const saveUserDetails = async (e) => {
    e.preventDefault();
  
    const userRef = doc(db, `restaurants/${restaurant}/customers`, currentUser.uid);
    try {
      await updateDoc(userRef, {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      });
      updateUserDetails({
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      });
      onClose();
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  const hasChanges = () => {
    if (!currentUser) {
      return false;
    }
    const initialFirstName = currentUser.firstName || "";
    const initialLastName = currentUser.lastName || "";
    const initialPhone = currentUser.phone || "";
    return firstName !== initialFirstName || lastName !== initialLastName || phone !== initialPhone;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal"
      style={customStyles}>
      <div className="modalContent">
        <div className="modalHeader">
          <span className="close" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                fill="white"
              />
            </svg>
          </span>
          <h4>Modify personal details</h4>
        </div>
        <Flex direction="column" p="24px" gap={8}>
          <Stack gap={4}>
            <FormControl isRequired>
              <FormLabel fontSize="13px">First name</FormLabel>
              <Input
                size="lg"
                variant="filled"
                focusBorderColor="#121212"
                name="firstName"
                type="text"
                placeholder="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontSize="13px">Last name</FormLabel>
              <Input
                size="lg"
                variant="filled"
                focusBorderColor="#121212"
                name="lastName"
                type="text"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontSize="13px">Phone number</FormLabel>
              <PhoneInput
              country={"lu"}
              value={phone}
              onChange={(value) => setPhone(value)}
              containerStyle={{ width: "100%", marginTop: "8px" }}
              inputStyle={{
                width: "100%",
                height: "37.5px",
                border: "1px solid var(--borders)",
                background: "transparent",
                fontWeight: "400 !important",
                fontFamily: "'Inter', sans-serif",
              }}
            />
            
            </FormControl>
          </Stack>
          <Button
            size="lg"
            isDisabled={!hasChanges()}
            className="primary"
            onClick={saveUserDetails}>
            Save Changes
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default UserDetails;
