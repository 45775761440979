// CheckoutActivationRoute.js
import useSettings from '../components/frontend/hooks/useSettings';
import CheckoutElements from './CheckoutElements';
import NotFound from '../NotFound';
import { useShopContext } from './context/ShopContext';
import Spinner from "../components/Spinner";


const CheckoutActivationRoute = ({ restaurantId }) => {
  
  const { isLoading } = useSettings(restaurantId);
  const { modules } = useShopContext();


  if (isLoading) {
    return <Spinner />; 
  } else if ( modules?.shop ) {
    return <CheckoutElements restaurantId={restaurantId} />;
  } else {
    return <NotFound restaurantId={restaurantId} />;
  }
};

export default CheckoutActivationRoute;
