import React, { useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import useCoupons from "../../../hooks/useCoupons";
import { TicketPercent } from "lucide-react";

const Coupon = ({ restaurantId }) => {
  const {
    couponCode,
    setCouponCode,
    handleApplyCoupon,
    userCoupons,
    resetCoupon,
  } = useCoupons(restaurantId);

  const handleSelectCoupon = (coupon) => {
    // Si le coupon sélectionné est déjà actif, on le désélectionne (null)
    if (coupon.name === couponCode) {
      setCouponCode(null); // Désélectionner le coupon
    } else {
      setCouponCode(coupon.name); // Sélectionner le coupon
    }
  };

  useEffect(() => {
    if (couponCode) {
      handleApplyCoupon();
      resetCoupon();
    }
  }, [couponCode]);

  return (
    <>
      {userCoupons.length > 0 && (
        <Flex direction="column" background="white" p={8} gap={4}>
          <Flex gap={8} direction={"column"}>
            <h4 style={{ fontWeight: "bold" }}>Available coupons</h4>
            <Flex direction="row" gap={2} flexWrap={"wrap"}>
              {userCoupons.map((coupon) => {
                const isActive = coupon.name === couponCode;

                // Convertir la date ISO en objet Date JavaScript
                let formattedExpirationDate = "No expiration date";

                if (coupon.expirationDate) {
                  try {
                    // Comme expirationDate est une chaîne ISO, nous pouvons directement la convertir en objet Date
                    const expirationDate = new Date(coupon.expirationDate);

                    // Formater la date en une chaîne lisible (par exemple : 'DD/MM/YYYY')
                    formattedExpirationDate =
                      expirationDate.toLocaleDateString();
                  } catch (error) {
                    console.error(
                      "Error formatting date: ",
                      coupon.expirationDate,
                      error
                    );
                  }
                }

                return (
                  <Flex
                    w={"100%"}
                    px={4}
                    py={3}
                    key={coupon.id}
                    onClick={() => handleSelectCoupon(coupon)}
                    border={"2px solid"}
                    borderRadius={8}
                    cursor="pointer"
                    transition={"all 0.3s ease"}
                    borderColor={isActive ? "var(--grey700)" : "var(--borders)"}
                    color={"var(--grey700)"}>
                    <Flex
                      direction={"row"}
                      gap={4}
                      opacity={isActive ? "1" : ".5"}
                      alignItems={"center"}>
                      <TicketPercent size={20} />
                      <Flex direction={"column"} gap={0}>
                        <Text
                          fontWeight={"bold"}
                          fontSize={20}
                          color={"var(--grey700)"}
                          lineHeight={1}>
                          -{coupon.couponValue}
                        </Text>
                        <Text fontSize={12}>
                          Expire on {formattedExpirationDate}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Coupon;
