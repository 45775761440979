import { useShopContext } from "../../context/ShopContext";
import { Flex } from "@chakra-ui/react";
import { Zap } from "lucide-react";
import { DateTime } from 'luxon';

const NextAvailableSlot = () => {
  const { nextAvailableSlot } = useShopContext();

  if (!nextAvailableSlot) return null;

  const now = DateTime.local(); // Heure actuelle
  const isToday = nextAvailableSlot.hasSame(now, 'day'); // Vérifie si c'est aujourd'hui
  const isTomorrow = nextAvailableSlot.hasSame(now.plus({ days: 1 }), 'day'); // Vérifie si c'est demain

  const label = isToday ? 'Today' : isTomorrow ? 'Tomorrow' : ''; // Détermine le label Today/Tomorrow
  const formattedSlot = nextAvailableSlot.toLocaleString(DateTime.TIME_SIMPLE); // Assure-toi de formater

  return (
    <>
      {formattedSlot && (
        <Flex direction={"row"} justifyContent={"space-between"} alignItems={"center"} w="100%">
          <Flex alignItems="center" gap={3}>
            <Zap size={20}/>
            <h5>Next availability</h5>
          </Flex>
          <Flex color="var(--grey500)" direction={"row"} gap={1}>
            {label} {formattedSlot} {/* Affiche Today/Tomorrow suivi de l'heure */}
          </Flex> 
        </Flex>
      )}
    </>
  );
};

export default NextAvailableSlot;

