import { useShopContext } from '../context/ShopContext';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { toast } from 'react-toastify';

const useCart = () => {
   
    const { restaurantId, cartItems, setCartUpdated, shopSettings, selectedLanguage, calculateSubtotal, deliveryMode} = useShopContext();

    const getOrCreateCartId = () => {
        let cartId = localStorage.getItem("cartId");
        if (!cartId) {
          cartId = Math.random().toString(36).substring(2, 15);
          localStorage.setItem("cartId", cartId);
        }
        return cartId;
    };
    
    const addItemToCart = async (cartItem, allLanguages) => {
        const maxItemsPerOrder = shopSettings?.general?.maxItemsPerOrder || Infinity; // Limite des articles
        const cartId = getOrCreateCartId();
        const cartRef = doc(db, `restaurants/${restaurantId}/cart`, cartId);
        const cartSnap = await getDoc(cartRef);
      
        // Compter le nombre total d'articles dans le panier
        let totalItemsInCart = cartItems.reduce((total, item) => total + item.quantity, 0);
      
        // Vérification si l'ajout dépasse la limite de `maxItemsPerOrder`
        if (totalItemsInCart + cartItem.quantity > maxItemsPerOrder) {
          toast.error(`You cannot add more than ${maxItemsPerOrder} items to your order.`);
          return;
        }
        
      
        if (cartSnap.exists()) {
          let cartData = cartSnap.data();
          const itemIndex = cartData.items.findIndex(
            (i) =>
              i.itemId === cartItem.itemId &&
              ((i.variation &&
                cartItem.variation &&
                i.variation.name === cartItem.variation.name) ||
                (!i.variation && !cartItem.variation)) &&
              i.note === cartItem.note &&
              compareExtras(i.extras, cartItem.extras)
          );
      
          if (itemIndex > -1) {
            cartData.items[itemIndex].quantity += cartItem.quantity;
          } else {
            cartData.items.push(cartItem);
          }
          await updateDoc(cartRef, { items: cartData.items });
        } else {
          await setDoc(cartRef, {
            items: [cartItem],
            cartId: cartId,
          });
        }
        setCartUpdated((prev) => !prev);
      };
      
      
    function compareExtras(extras1 = [], extras2 = [], selectedLanguage = 'en') {
        if (extras1.length !== extras2.length) return false;
    
        const sortedExtras1 = [...extras1].sort((a, b) =>
          a.name[selectedLanguage].localeCompare(b.name[selectedLanguage])
        );
        const sortedExtras2 = [...extras2].sort((a, b) =>
          a.name[selectedLanguage].localeCompare(b.name[selectedLanguage])
        );
      
        return sortedExtras1.every(
          (extra, index) => extra.name[selectedLanguage] === sortedExtras2[index].name[selectedLanguage]
        );
    };

    const updateItemQuantity = async (itemId, variationName, quantity, note = null, extras = []) => {
        const cartId = localStorage.getItem("cartId");
        if (!cartId) return;
        
        const cartRef = doc(db, `restaurants/${restaurantId}/cart`, cartId);
        const cartSnap = await getDoc(cartRef);
      
        const maxItemsPerOrder = shopSettings?.general?.maxItemsPerOrder || Infinity; // Limite des articles
      
        if (cartSnap.exists()) {
          let cartData = cartSnap.data();
      
          // Calculer le nombre total d'articles dans le panier avant la modification
          let totalItemsInCart = cartData.items.reduce((total, item) => total + item.quantity, 0);
      
          const itemIndex = cartData.items.findIndex((i) => {
            const isItemIdMatch = i.itemId === itemId;
            const isVariationMatch = variationName ? i.variation && i.variation.name === variationName : !i.variation;
            const isNoteMatch = i.note === note || (i.note === undefined && note === null);
            const isExtrasMatch = extras.length === (i.extras ? i.extras.length : 0) &&
                                  extras.every(extra => i.extras.some(e => e.name[selectedLanguage] === extra.name[selectedLanguage]));
        
            return isItemIdMatch && isVariationMatch && isNoteMatch && isExtrasMatch;
          });
      
          if (itemIndex > -1 && quantity > 0) {
            const currentItem = cartData.items[itemIndex];
            const totalQuantityIfUpdated = totalItemsInCart - currentItem.quantity + quantity;
      
            // Vérification de la limite de `maxItemsPerOrder`
            if (totalQuantityIfUpdated > maxItemsPerOrder) {
              toast.error(`You cannot add more than ${maxItemsPerOrder} items to your order.`);
              return;
            }
      
            // Mettre à jour la quantité si la limite n'est pas dépassée
            cartData.items[itemIndex].quantity = quantity;
      
          } else if (itemIndex > -1 && quantity <= 0) {
            // Si la quantité est 0 ou moins, supprimer l'article
            cartData.items.splice(itemIndex, 1);
          }
      
          await updateDoc(cartRef, cartData);
          setCartUpdated((prev) => !prev);
        }
      };
    

      const calculateMinimumOrderAmountDifference = () => {
        const totalValue = parseFloat(calculateSubtotal()); // Obtenir le sous-total du panier
        const minimumOrderAmountValue =
          deliveryMode === "delivery"
            ? parseFloat(shopSettings.delivery.minimumOrderAmount) // Minimum pour la livraison
            : parseFloat(shopSettings.takeAway.minimumOrderAmount); // Minimum pour le pickup
        
        const differenceValue = minimumOrderAmountValue - totalValue;
        return differenceValue > 0 ? differenceValue : 0; // Si la différence est positive, renvoyer la valeur, sinon renvoyer 0
      };
      


    


    return { 
    

        addItemToCart,
        updateItemQuantity,
        calculateMinimumOrderAmountDifference

     };
};

export default useCart;
