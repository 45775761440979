import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, Timestamp } from "firebase/firestore";
import { db } from '../firebaseConfig';
import { useShopContext } from '../shop/context/ShopContext';
import { useAuth } from '../shop/context/AuthContext';

const useCoupons = (restaurantId) => {
  
  const [userCoupons, setUserCoupons] = useState([]);
  const { calculateSubtotal, couponCode, setCouponCode, discountAmount, setDiscountAmount, setTotalAfterDiscountValue, formatPrice, isCouponValid, setIsCouponValid, setCouponDetails  } = useShopContext();
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchCoupons = async () => {
      if (!currentUser) return;
  
      const couponsRef = collection(db, `restaurants/${restaurantId}/coupons`);
  
      // Query for "All customers"
      const allCustomersQuery = query(
        couponsRef,
        where('application', '==', 'All customers')
      );
      const allCustomersSnapshot = await getDocs(allCustomersQuery);
  
      // Query for user-specific coupons by email
      const userSpecificQuery = query(
        couponsRef,
        where('application', 'array-contains', currentUser.email)
      );
      const userSpecificSnapshot = await getDocs(userSpecificQuery);
  
      // Combine results from both queries
      let fetchedCoupons = [
        ...allCustomersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })),
        ...userSpecificSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      ];
  
      // Filtrer les coupons qui ont atteint leur limite d'utilisation ou qui sont expirés
const validCoupons = [];
for (const coupon of fetchedCoupons) {
  const usageCount = await checkCouponUsage(coupon.id, currentUser.uid);

  // Si maxUses est 0, cela signifie que le coupon peut être utilisé de manière illimitée
  if (coupon.maxUses === 0 || usageCount < coupon.maxUses) {
    // Si vous avez un champ expirationDate
    if (coupon.expirationDate) {
      const expirationDate = new Date(coupon.expirationDate);
      if (expirationDate > new Date()) {
        validCoupons.push(coupon);
      }
    } else {
      validCoupons.push(coupon);
    }
  }
}

setUserCoupons(validCoupons);

    };
  
    fetchCoupons();
  }, [restaurantId, currentUser]);
  

  const handleApplyCoupon = async (orderId) => {
  
    const foundCoupon = userCoupons.find(coupon => coupon.name === couponCode);
  
    if (!foundCoupon) {
      console.log("Coupon not found:", couponCode);
      setIsCouponValid(false);
      setDiscountAmount(0);
      setCouponDetails(null);
      return;
    }
  
    // Check usage limit if maxUses is not 0
    if (foundCoupon.maxUses !== 0) {
      const usageCount = await checkCouponUsage(foundCoupon.id, currentUser.uid);
  
      if (usageCount >= foundCoupon.maxUses) {
        setIsCouponValid(false);
        setDiscountAmount(0);
        setCouponDetails(null);
        return;
      }
    }
  
    const discountValue = parseFloat(foundCoupon.couponValue.split(' ')[0]);
    const discountType = foundCoupon.couponValue.split(' ')[1];
    setIsCouponValid(true);
  
    let discountAmountValue = 0;
    const total = parseFloat(calculateSubtotal().replace(",", "."));
  
    if (discountType === '%') {
      discountAmountValue = total * (discountValue / 100);
    } else {
      discountAmountValue = discountValue;
    }
  
    setDiscountAmount(discountAmountValue);
    setTotalAfterDiscountValue(formatPrice(total - discountAmountValue));
  
    // Log coupon usage with both userId and userEmail
    await logCouponUsage(foundCoupon.id, currentUser.uid, currentUser.email, orderId);
  
    setCouponDetails(foundCoupon);
    console.log("Coupon applied successfully with discount:", discountAmountValue);
  };  

  const resetCoupon = () => {
    setDiscountAmount(0); // Réinitialiser la réduction
    const total = parseFloat(calculateSubtotal().replace(",", "."));
    setTotalAfterDiscountValue(formatPrice(total)); // Recalculer le total sans réduction
    setCouponDetails(null); // Réinitialiser les détails du coupon
  };

const checkCouponUsage = async (couponId, userId) => {
  const usageRef = collection(db, `restaurants/${restaurantId}/couponsUsageLogs`);
  const q = query(
    usageRef,
    where('userId', '==', userId),
    where('couponId', '==', couponId)
  );
  const usageSnapshot = await getDocs(q);

  return usageSnapshot.size;
};


const logCouponUsage = async (couponId, userId, userEmail, orderId) => {
  
  if (!orderId || typeof orderId !== "string" || orderId.trim() === "") {
    console.error("Invalid orderId passed to logCouponUsage:", orderId);
    return;
  }

  const usageRef = collection(db, `restaurants/${restaurantId}/couponsUsageLogs`);
  const newLog = {
    couponId,
    userId,
    userEmail,  // Add userEmail to the log
    orderId: orderId,
    usedAt: Timestamp.now()  // Firestore Timestamp for the current time
  };
  
  await addDoc(usageRef, newLog);
  console.log("Coupon usage logged successfully with userEmail:", newLog);
};


  return {
    couponCode,
    setCouponCode,
    discountAmount,
    isCouponValid,
    handleApplyCoupon,
    userCoupons,
    checkCouponUsage,
    logCouponUsage, resetCoupon
  };
};

export default useCoupons;
