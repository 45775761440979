import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    FormControl,
    FormLabel,
    InputGroup,
    InputRightElement,
    Flex,
  } from "@chakra-ui/react";
  import { useState } from "react";
  import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
  import { toast } from "react-toastify";
  
  const PasswordModal = ({ isOpen, onClose, hasPasswordProvider, hasGoogleProvider, currentUser }) => {
    
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleClick = () => setShowPassword(!showPassword);
  
    // Fonction pour réauthentifier l'utilisateur avant de changer le mot de passe
    const reauthenticateUser = async () => {
      try {
        if (currentPassword) {
          const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
          await reauthenticateWithCredential(currentUser, credential);
          return true;
        }
        return false;
      } catch (error) {
        console.error("Reauthentication failed:", error);
        toast.error("Current password is incorrect. Please reauthenticate.");
        return false;
      }
    };

    // Fonction pour mettre à jour le mot de passe
    const handleUpdatePassword = async (e) => {
      e.preventDefault();
      setIsLoading(true);
  
      if (newPassword !== confirmPassword) {
        toast.error("Passwords do not match");
        setIsLoading(false);
        return;
      }

      try {
        // Si l'utilisateur est un utilisateur de type `password`, nous devons d'abord réauthentifier
        if (hasPasswordProvider) {
          const isReauthenticated = await reauthenticateUser();
          if (!isReauthenticated) {
            setIsLoading(false);
            return;
          }
        }

        // Mise à jour du mot de passe
        await updatePassword(currentUser, newPassword);
        toast.success("Password updated successfully");
        onClose(); // Fermer la modal après mise à jour

      } catch (error) {
        console.error("Error updating password:", error);
        toast.error("An error occurred while updating your password.");
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex gap={4} direction={"column"}>
            {hasPasswordProvider && (
              <FormControl>
                <FormLabel fontSize={"12px"} mb={1}>
                  Current password
                </FormLabel>
                <InputGroup>
                  <Input
                    focusBorderColor="#121212"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Current password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.5rem"
                      size="xs"
                      onClick={handleClick}
                      variant={"outline"}
                      isDisabled={!currentPassword}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            )}

            <FormControl>
              <FormLabel fontSize={"12px"} mb={1}>
                New password
              </FormLabel>
              <InputGroup>
                <Input
                  focusBorderColor="#121212"
                  name="newPassword"
                  type={showPassword ? "text" : "password"}
                  placeholder="New password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.5rem"
                    size="xs"
                    onClick={handleClick}
                    variant={"outline"}
                    isDisabled={!newPassword}>
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl>
              <FormLabel fontSize={"12px"} mb={1}>
                Confirm password
              </FormLabel>
              <InputGroup>
                <Input
                  focusBorderColor="#121212"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            </Flex>
          </ModalBody>
  
          <ModalFooter>
            
            <Button variant="ghost" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button
              bg={"var(--brand)"}
              color={'white'}
              onClick={handleUpdatePassword}
              isLoading={isLoading} // Loading state while saving
              isDisabled={!newPassword || !confirmPassword} // Disabled if passwords are not provided
            >
              Update Password
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  export default PasswordModal;
