import { Flex, Text } from "@chakra-ui/react";
import { CalendarClock } from "lucide-react";

const Planification = ({ isNextAvailableSelected, selectedSlot }) => {
  
  return (
    <>
    <Flex
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      w="100%">      
      <Flex flex="1" >
        <Flex alignItems="center" gap={3}>
            <CalendarClock size={20}/>
            <h5>Schedule for later</h5>
        </Flex>
      </Flex>
      <Flex>
      <Text color="var(--grey500)">{isNextAvailableSelected ? "Select a slot" : selectedSlot || "Select a slot"}
      </Text>
      </Flex>
    </Flex>
    
    
   
  </>
  );
};

export default Planification;
