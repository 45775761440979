import { useState, useEffect } from "react";
import { db } from "../../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";


const useSettings = ( restaurantId ) => {
	
  const [isLoading, setIsLoading] = useState(true);

  const [settings, setSettings] = useState({});
  const [logo, setLogo] = useState('');
  const [brandColor, setBrandColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [language, setLanguage] = useState([]);
  const [secondaryLanguages, setSecondaryLanguages] = useState([]);
    

  useEffect(() => {
    setIsLoading(true);
    const fetchSettings = async () => {
      const settingsRef = doc(db, `restaurants/${restaurantId}/settings`, 'config');
      try {
        const docSnap = await getDoc(settingsRef);
        if (docSnap.exists()) {
          const settingsData = docSnap.data();
          setSettings(settingsData);
          setLanguage(settingsData.language);
          setLogo(settingsData.logo);
          setBrandColor(settingsData.brandColor);
          setBackgroundColor(settingsData.backgroundColor);
          setTextColor(settingsData.textColor);
          setBackgroundImage(settingsData.backgroundImage);
          setSecondaryLanguages(settingsData.secondaryLanguages);
          document.documentElement.style.setProperty('--restaurantColor', settingsData.brandColor);
          document.documentElement.style.setProperty('--background-color', settingsData.backgroundColor);
          document.documentElement.style.setProperty('--text-color', settingsData.textColor);
        } else {
          console.log('No settings data found');
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (restaurantId) {
      fetchSettings();
    } else {
      console.log('Invalid or missing restaurantId');
    }
  }, [restaurantId]);
  
  
  




	return {
        settings,
        logo,
        setLogo,
        brandColor,
        setBrandColor,
        backgroundColor,
        setBackgroundColor,
        backgroundImage,
        setBackgroundImage,
        textColor,
        setTextColor,
        language,
        setLanguage,
        secondaryLanguages,
        setSecondaryLanguages,
        isLoading
	};
};

export default useSettings;
