import React, { useEffect, useState } from "react";
import { db } from "../../../firebaseConfig";
import { useAuth } from "../../context/AuthContext";
import {
  collectionGroup,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";
import { Flex, Box, Image, Divider, Skeleton } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import OrderInvoice from "./OrderInvoice";
import { pdf } from "@react-pdf/renderer";

// Composant pour afficher une commande individuelle
const OrderItem = ({ order, generalSettings, openInvoice }) => {
  const navigate = useNavigate();
  
  const getTotalItemsCount = (order) =>
    order.cartItems.reduce((total, item) => total + item.quantity, 0);

  const findCartItemPhoto = (cartItems) => {
    const itemWithPhoto = cartItems.find((item) => item.photo);
    return itemWithPhoto ? itemWithPhoto.photo : null;
  };

  const handleOrderClick = (order) => {
    navigate (`/account/orders/${order.restaurantId}/${order.id}`);
  }

  const photoSrc = findCartItemPhoto(order.cartItems) || order.restaurantDetails.logo;

  return (
    <Flex gap={0} direction="column" >
      <Flex key={order.id} direction="row" gap={4} align="center" onClick={() => handleOrderClick(order)} cursor={"pointer"} _hover={{ bg: "var(--grey50)" }} p={4}>
        <Box
          w={{ desktop: "72px", mobile: "42px" }}
          h={{ desktop: "72px", mobile: "42px" }}
          bg={"var(--grey100)"}
          overflow={"hidden"}
          borderRadius={"8px"}
        >
          <Image
            src={photoSrc}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
        <Flex direction="column">
          <h5 style={{ textTransform: "capitalize" }}>
            <Link to={`/${order.restaurantId}/shop`}>
              {order.restaurantId}
            </Link>{" "}
            <span style={{ color: "var(--grey500)" }}>
              • Order #{order.id}
            </span>{" "}
            • {order.status}
          </h5>
          <p
            style={{
              fontSize: "14px",
              color: "var(--grey600)",
              whiteSpace: "nowrap",
            }}
          >
            {getTotalItemsCount(order)} items for {order.total}€ •{" "}
            {order.fullDate}
            <Flex direction="row" flexWrap="wrap">
              {order.status === "completed" && order.paymentStatus === "Paid" && (
                <>
                  <span style={{ margin: "0 4px" }}>•</span>

                  <div
                    style={{
                      color: "#121212",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: "500",
                    }}
                    onClick={() => openInvoice(order)}
                  >
                    Download invoice
                  </div>
                </>
              )}
            </Flex>
          </p>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" />
    </Flex>
  );
};

const UserOrders = () => {
  const [orders, setOrders] = useState([]);
  const { currentUser } = useAuth();
  const [generalSettings, setGeneralSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGeneralSettings = async () => {
      try {
        const settingsRef = doc(db, "general/settings");
        const docSnap = await getDoc(settingsRef);
        if (docSnap.exists()) {
          setGeneralSettings(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching general settings:", error);
      }
    };
    fetchGeneralSettings();
  }, []);

  
  useEffect(() => {
    const fetchOrders = async () => {
      if (currentUser) {
        setIsLoading(true);
        try {
          const ordersQuery = query(
            collectionGroup(db, "orders"),
            orderBy("createdAt", "desc"),
            where("customerDetails.userId", "==", currentUser.uid)
          );
          const querySnapshot = await getDocs(ordersQuery);
          const fetchedOrders = querySnapshot.docs.map((doc) => {
            const orderData = doc.data();
            const fullDate = orderData.createdAt.toDate();
            return {
              id: doc.id,
              fullDate: format(fullDate, "d MMM yyyy 'at' HH:mm"),
              ...orderData,
            };
          });
          setOrders(fetchedOrders);
        } catch (error) {
          console.error("Error fetching orders:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchOrders();
  }, [currentUser]);

  const openInvoice = async (order) => {
    try {
      const blob = await pdf(
        <OrderInvoice order={order} generalSettings={generalSettings} />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Invoice-${order.id}.pdf`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error generating invoice:", error);
    }
  };

  return (
    <Flex gap={8} direction="column" flex={1}>
      {isLoading ? (
        <>
        <Skeleton h={{ desktop: "72px", mobile: "42px" }} />
        <Skeleton h={{ desktop: "72px", mobile: "42px" }} />
        <Skeleton h={{ desktop: "72px", mobile: "42px" }} />
        <Skeleton h={{ desktop: "72px", mobile: "42px" }} />
        <Skeleton h={{ desktop: "72px", mobile: "42px" }} />
        <Skeleton h={{ desktop: "72px", mobile: "42px" }} />
        <Skeleton h={{ desktop: "72px", mobile: "42px" }} />
        <Skeleton h={{ desktop: "72px", mobile: "42px" }} />
        </>
      ) : orders.length > 0 ? (
        <Flex direction="column">
          {orders.map((order) => (
            <OrderItem
              key={order.id}
              order={order}
              generalSettings={generalSettings}
              openInvoice={openInvoice}
            />
          ))}
        </Flex>
      ) : (
        <p>No orders found.</p>
      )}
    </Flex>
  );
};

export default UserOrders;
