import { useShopContext } from '../context/ShopContext';
import useCart from './useCart';

const useCheckout = () => {
   

    const { cartItems, isFormValid } = useShopContext();
    const { calculateMinimumOrderAmountDifference} = useCart();
   
    const isCheckoutDisabledCart = () => {
        const cartIsEmpty = cartItems.length === 0;
        const belowMinimumOrder = calculateMinimumOrderAmountDifference() > 0;
        return cartIsEmpty || belowMinimumOrder;
    };

    const isCheckoutDisabledCheckout = () => {
        const cartIsEmpty = cartItems.length === 0;
        const belowMinimumOrder = calculateMinimumOrderAmountDifference() > 0;
        const formInvalid = !isFormValid();
        return cartIsEmpty || belowMinimumOrder || formInvalid;
    };

    
    



    return { 
        isCheckoutDisabledCart,
        isCheckoutDisabledCheckout

     };
};

export default useCheckout;
