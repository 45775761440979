import { useNavigate } from 'react-router-dom';
import { useShopContext } from '../../context/ShopContext';
import CartContent from './CartContent';
import { Flex, Box, Button } from "@chakra-ui/react";
import useCheckout from '../../hooks/useCheckout';

const Cart = ({ restaurantId }) => {
    
    const { cartItems, styles, totalWithTip, clearCart } = useShopContext();
    const { isCheckoutDisabledCart } = useCheckout();
    const navigate = useNavigate();
    
    const handleCheckout = () => {
        if (!isCheckoutDisabledCart()) {
            navigate(`/${restaurantId}/shop/checkout`);
        }
    };

    const checkoutDisabled = isCheckoutDisabledCart();


    return (      
        <Box className={styles.cart} mt={{ desktop: "-94px", mobile: 0}} zIndex={999999} border="1px solid var(--borders)">
           <Flex className={styles.cartContent} flex={1}>
             <Flex direction="row" justifyContent="space-between">
                <h4>Your bag</h4>                  
                <Button variant={"outline"} size={"sm"} onClick={clearCart} fontSize={"12px !important"} isDisabled={cartItems.length === 0}>Clear cart</Button>
             </Flex>
            <CartContent />
            <div className={styles.cartActions}>
                <Button 
                    size="lg"
                    onClick={handleCheckout} 
                    className={`btn primary ${checkoutDisabled ? styles.inactive : ''}`}
                    isDisabled={checkoutDisabled}>
                        <Box className={styles.bgHover}></Box> 
                        <Box zIndex={2} gap={1}>Checkout {cartItems.length > 0 && <span>{totalWithTip()}€</span>}</Box>
                        
                </Button>
            </div> 
        </Flex>
    </Box>
    );
};

export default Cart;
