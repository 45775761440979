import { useState} from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { db } from "../../firebaseConfig";
import { doc, setDoc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { auth } from "../../firebaseConfig"; // Assurez-vous d'importer votre configuration Firebase
import {
  Box,
  Flex,
  Stack,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Divider,
  AbsoluteCenter,
} from "@chakra-ui/react";
import { FaFacebook } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import logo from "../../img/guddenApp2.svg";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const LoginPage = () => {
  const { restaurantId } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [isLoading, setIsLoading] = useState(false); // Ajout d'un état de chargement
  const navigate = useNavigate();
  
   // Définir le restaurantId à partir de l'URL, sessionStorage ou paramètres de requête
   const urlParams = new URLSearchParams(window.location.search);
   const restaurantIdFromURL = urlParams.get('restaurantId');
   const restaurant = restaurantId || restaurantIdFromURL || sessionStorage.getItem('restaurantId');
 
   // Si aucun restaurantId n'est disponible, afficher une erreur ou rediriger
   if (!restaurant) {
     console.error("Restaurant ID is missing");
     // Rediriger vers une page d'erreur ou de sélection
     // return <Navigate to="/error-page" />;
   } else {
     // Stocker le restaurantId dans sessionStorage
     sessionStorage.setItem('restaurantId', restaurant);
   }
 
   const handleLoginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User after Google login:", user); // Log pour vérifier l'utilisateur
      await createUserInFirestore(user);
  
      const prevPath = sessionStorage.getItem('prevPath') || '/';
      console.log("Redirection vers :", prevPath); // Vérifiez le chemin dans prevPath
      navigate(prevPath, { replace: true });
      sessionStorage.removeItem('prevPath');
    } catch (error) {
      toast.error("Login with Google failed: " + error.message);
      console.error("Login with Google failed: ", error);
    }
  };
  
  
  const handleLoginWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await createUserInFirestore(user);
      const prevPath = sessionStorage.getItem('prevPath') || '/';
      navigate(prevPath || '/', { replace: true });
      sessionStorage.removeItem('prevPath');
    } catch (error) {
      toast.error("Login with Facebook failed: " + error.message);
      alert("Login with Facebook failed: " + error.message);
    }
};

const createUserInFirestore = async (user) => {
  // Recherche l'utilisateur existant avec l'email avant de créer un nouvel utilisateur
  const customersCollection = collection(db, `restaurants/${restaurant}/customers`); // Utilisez `collection()` ici
  const existingUserQuery = query(customersCollection, where("email", "==", user.email)); // Utilisez `query()` et `where()`
  const querySnapshot = await getDocs(existingUserQuery); // Utilisez `getDocs()` pour exécuter la requête

  if (!querySnapshot.empty) {
    // Si un utilisateur avec cet email existe déjà, fusionner les informations utilisateur
    querySnapshot.forEach(async (docSnapshot) => {
      const existingUserData = docSnapshot.data();
      
      // Mise à jour de l'utilisateur existant avec les informations du compte Google
      await updateDoc(docSnapshot.ref, {
        photoURL: user.photoURL || existingUserData.photoURL,
        lastLogin: new Date(), // Optionnel: stocker la dernière connexion
      });
    });
  } else {
    // Si aucun utilisateur avec cet email n'existe, créer un nouvel utilisateur dans Firestore
    const userRef = doc(db, `restaurants/${restaurant}/customers`, user.uid);
    await setDoc(userRef, {
      uid: user.uid,
      email: user.email,
      firstName: user.displayName?.split(" ")[0] || "",
      lastName: user.displayName?.split(" ")[1] || "",
      photoURL: user.photoURL,
      role: 'customer',
      createdAt: new Date(),
      lastLogin: new Date(), // Optionnel: stocker la date de création et de dernière connexion
    });
    console.log("New user created in Firestore.");
  }
};






  
  const handleLoginWithEmail = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Commence le chargement
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const prevPath = sessionStorage.getItem('prevPath') || '/';
      navigate(prevPath || '/', { replace: true });
      sessionStorage.removeItem('prevPath');
      setIsLoading(false); // Arrête le chargement après une tentative réussie
    } catch (error) {
      setIsLoading(false); // Arrête le chargement en cas d'échec
      if (error.code === "auth/user-not-found") {
        try {
          await createUserWithEmailAndPassword(auth, email, password);
          const prevPath = sessionStorage.getItem('prevPath') || '/';
          navigate(prevPath, { replace: true });
          sessionStorage.removeItem('prevPath');
        } catch (signupError) {
          alert("Signup failed: " + signupError.message);
        }
      } else {
        alert("Login failed: " + error.message);
      }
    }
  };

  

  return (
    <>    <ToastContainer
    className="loginToast"
    autoClose={false}
    style={{ maxWidth: "500px !important" }}
    hideProgressBar={true}
    closeOnClick
    theme="colored"
    position="top-center"
/>
      <Flex
        width="100%"
        height={"calc(100vh - 96.55px - 51.5px)"}
        display="flex"
        alignItems="center"
        direction={"column"}
        gap={16}
        py={16}>
        
        
        <Link to="/"><img src={logo} alt="Logo" width={"62px"} /></Link>

        <Flex
          direction="column"
          maxW="360px"
          w={"100%"}
          p={6}
          mx="auto"
          gap={4}>
          <h4>Enter your email and password</h4>
          <form onSubmit={handleLoginWithEmail}>
            <Stack gap={2}>
              <Input
                size="lg"
                variant="filled"
                focusBorderColor="#121212"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <InputGroup size="lg">
                <Input
                  variant="filled"
                  focusBorderColor="#121212"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  type={show ? "text" : "password"}
                  placeholder="Password"
                />
                <InputRightElement>
                  <Button size="xs" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Button
                size="lg"
                bg="var(--brand)"
                color="white"
                type="submit"
                isLoading={isLoading}
                isDisabled={isLoading}
                >
                Continue
              </Button>
            </Stack>
            <Box position="relative" padding="6">
              <Divider />
              <AbsoluteCenter bg="white" px="4">
                or
              </AbsoluteCenter>
            </Box>
            <Stack>
              <Button size="lg" variant="outline" leftIcon={<FcGoogle />} onClick={handleLoginWithGoogle}>
                Login with Google
              </Button>
               {/* <Button size="lg" colorScheme={'facebook'} leftIcon={<FaFacebook />} onClick={handleLoginWithFacebook} isDisabled>Login with Facebook</Button> */}
              <Button as={Link} to="/register" variant="link" fontSize="13px !important" mt={2} textDecoration={"underline"}>Register with email</Button>
            </Stack>
          </form>
        </Flex>
        
      </Flex>
    </>
  );
};

export default LoginPage;
