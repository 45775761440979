// CheckoutElements.js
import React, { useState, useEffect } from "react";
import Checkout from "./components/checkout/Checkout";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useShopContext } from "./context/ShopContext";
import useStripe from "./hooks/useStripe";



const CheckoutElements = ({ restaurantId }) => {
  const { stripeKeys } = useShopContext();
  const { loadStripeKeys } = useStripe();
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    loadStripeKeys();
  }, [restaurantId]);
  
  
  useEffect(() => {
    if (stripeKeys && stripeKeys.PublicTest) {
      setStripePromise(loadStripe(stripeKeys.PublicTest)); // Utilisation de la clé publique pour l'initialisation de Stripe
    }
  }, [stripeKeys]);
  
  return (
    <Elements stripe={stripePromise}>
      <Checkout restaurantId={restaurantId} stripePromise={stripePromise} />
    </Elements>
  );
};

export default CheckoutElements;
