import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from "../../firebaseConfig";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import MenuSwitcher from './components/menu/MenuSwitcher';
import MenuList from './components/menu/MenuList';
import useSettings from './hooks/useSettings';
import styles from './frontend.module.css';
import { Helmet } from 'react-helmet';
import { Flex, Spinner, Center } from '@chakra-ui/react';
import Reservation from './components/reservations/Reservation';

const MenuFrontend = () => {
  const { restaurantId } = useParams();
  const [mainCategories, setMainCategories] = useState([]);
  const [frontendTags, setFrontendTags] = useState([]);
  const [currentMainCategory, setCurrentMainCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [extrasGroups, setExtrasGroups] = useState([]);
  const [tables, setTables] = useState([]);
  const [maxGuests, setMaxGuests] = useState(0);
  const [loading, setLoading] = useState(true);

  const { settings, logo, backgroundImage, language, secondaryLanguages } = useSettings(restaurantId);
  
  const defaultLanguage = language.length > 0 ? language[0] : { flag: '', label: 'Français', value: 'fr' };
  const initialLanguage = localStorage.getItem('selectedLanguage') || defaultLanguage.value;
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);
  
  const allLanguages = [
    defaultLanguage,
    ...secondaryLanguages.map(lang => lang.value)
  ].filter(Boolean);

  

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }, [selectedLanguage]);

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage.value);
  };

  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImage})`
  };

  useEffect(() => {
    document.body.classList.add('loading');
    const fetchMainCategories = async () => {
      if (restaurantId) {
        const querySnapshot = await getDocs(collection(db, `restaurants/${restaurantId}/menu`));
        const fetchedCategories = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(category => !category.id.endsWith('-categories') && category.published)
          .sort((a, b) => a.order - b.order);
  
        const mainCategoriesObject = {};
        fetchedCategories.forEach(category => {
          mainCategoriesObject[category.id] = category;
        });
  
        setMainCategories(mainCategoriesObject);
  
        const savedMainCategoryId = localStorage.getItem('currentMainCategory');
        const initialMainCategory = savedMainCategoryId && mainCategoriesObject.hasOwnProperty(savedMainCategoryId) 
                                    ? mainCategoriesObject[savedMainCategoryId] 
                                    : mainCategoriesObject[Object.keys(mainCategoriesObject)[0]];
        
        setCurrentMainCategory(initialMainCategory);
      }
    };
    fetchMainCategories();
  }, [restaurantId]);

  console.log("mainCategories number:", Object.keys(mainCategories).length);
  

  useEffect(() => {
    if (currentMainCategory && currentMainCategory.id) {
      localStorage.setItem('currentMainCategory', currentMainCategory.id);
    }
  }, [currentMainCategory]);

  useEffect(() => {
    const fetchSubCategories = async () => {
      if (currentMainCategory && currentMainCategory.id) {
        const path = `restaurants/${restaurantId}/menu/${currentMainCategory.id}-categories/categories`;
        const categoriesQuery = query(collection(db, path), orderBy('order'));
        const categoriesSnapshot = await getDocs(categoriesQuery);
        const fetchedCategories = categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSubCategories(fetchedCategories);
      }
    };

    fetchSubCategories();
  }, [currentMainCategory, restaurantId]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      if (currentMainCategory && currentMainCategory.id) {
        const path = `restaurants/${restaurantId}/menu/${currentMainCategory.id}/items`;
        const menuQuery = collection(db, path);
        const menuSnapshot = await getDocs(menuQuery);
        const fetchedMenuData = menuSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        fetchedMenuData.sort((a, b) => a.order - b.order);
        
        setMenuItems(fetchedMenuData);
        setLoading(false); // Set loading to false after fetching menu items
        document.body.classList.remove('loading'); // Remove loading class from body
      }
    };

    fetchMenuItems();
  }, [currentMainCategory, restaurantId]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        if (!currentMainCategory || !currentMainCategory.id || !restaurantId) {
          return;
        }

        const querySnapshot = await getDocs(query(collection(db, `restaurants/${restaurantId}/menu/${currentMainCategory.id}/tags`)));
        const tagsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFrontendTags(tagsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des tags :", error);
      }
    };

    fetchTags();
  }, [currentMainCategory, restaurantId]);

  useEffect(() => {
    const fetchExtrasGroups = async () => {
      try {
        if (!restaurantId || Object.keys(mainCategories).length === 0) {
          return;
        }

        let allExtrasGroups = [];
        for (const categoryId of Object.keys(mainCategories)) {
          const path = `restaurants/${restaurantId}/menu/${categoryId}/extras`;
          const querySnapshot = await getDocs(collection(db, path));
          const extrasGroupsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            mainCategoryId: categoryId, // Ajoutez cette ligne si vous avez besoin de savoir à quelle catégorie principale chaque groupe d'extras appartient
          }));

          allExtrasGroups = allExtrasGroups.concat(extrasGroupsData);
        }

        setExtrasGroups(allExtrasGroups);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des groupes d'extras :",
          error
        );
      }
    };

    fetchExtrasGroups();
  }, [restaurantId, mainCategories]);

  useEffect(() => {
    const fetchTables = async () => {
      const querySnapshot = await getDocs(collection(db, `restaurants/${restaurantId}/tables`));
      if (querySnapshot.empty) {
        console.log("No tables data available for this restaurant.");
        setMaxGuests(0); // Aucun invité ne peut être réservé si aucune table n'est disponible
        setTables([]); // Aucune table disponible
        return;
      }
      let maxCapacity = 0;
      let combinableTablesCapacity = 0;
      let fetchedTables = [];

      fetchedTables = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const capacity = data.capacity || 0; // Utilisez 0 si la capacité n'est pas définie
        maxCapacity = Math.max(maxCapacity, capacity);
        return { ...data, capacity };
      }).sort((a, b) => b.capacity - a.capacity);

      if (fetchedTables.length > 1) {
        combinableTablesCapacity = fetchedTables[0].capacity + fetchedTables[1].capacity;
      } else if (fetchedTables.length === 1) {
        combinableTablesCapacity = fetchedTables[0].capacity;
      }
      
      setMaxGuests(combinableTablesCapacity);
      setTables(fetchedTables); // Stocker toutes les tables dans l'état
    };

    fetchTables();
  }, [restaurantId]);

  function capitalizeWords(str) {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  if (loading) {
    return (
      <Center h={"100vh"} w="100vw" bg="var(--background-color)">
        <Spinner size="xl" color='var(--restaurantColor)' />
      </Center>
    );
  }

  return (
    <Flex className={styles.frontend}>

      <div className={styles.backgroundImage} style={backgroundImageStyle}></div>

      <Helmet>
        <title>{capitalizeWords(restaurantId)} - Menu Digital</title>
        <meta name="description" content={`Menu digital de ${capitalizeWords(restaurantId)}`} />
      </Helmet>

      <MenuList 
        menuItems={menuItems} 
        subCategories={subCategories.filter(cat => !cat.isUncategorized)}
        currentMainCategory={currentMainCategory}
        defaultLanguage={defaultLanguage}
        selectedLanguage={selectedLanguage}
        secondaryLanguages={secondaryLanguages}
        allLanguages={allLanguages}
        styles={styles}
        tags={frontendTags}
        restaurantId={restaurantId}
        backgroundImageStyle={backgroundImageStyle}
        logo={logo.menu}
        handleLanguageChange={handleLanguageChange}
        extrasGroups={extrasGroups}
      />

    {Object.keys(mainCategories).length > 1 &&
      <MenuSwitcher
        mainCategories={mainCategories} 
        onCategoryChange={setCurrentMainCategory}
        currentMainCategory={currentMainCategory} 
        defaultLanguage={defaultLanguage}
        selectedLanguage={selectedLanguage}
        styles={styles} 
      />
    }

      {(settings?.modules?.reservations === true && tables.length > 0) &&
        <Reservation 
          restaurantId={restaurantId}
          maxGuests={maxGuests}
          settings={settings}
          serviceDuration={settings.reservationsSettings.serviceDuration}
        />
      }
    </Flex>
  );
}

export default MenuFrontend;
