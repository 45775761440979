import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./shop/context/AuthContext";
import { ChakraProvider } from "@chakra-ui/react";
import useBreakpoints from "./hooks/useBreakpoints";
import MainProvider from "./shop/MainProvider";
import "./App.css";
import Spinner from "./components/Spinner";
import MenuFrontend from "./components/frontend/MenuFrontend";
import PrivateRoute from "./shop/components/PrivateRoute";
import ShopActivationRoute from "./shop/ShopActivationRoute";
import CheckoutActivationRoute from "./shop/CheckoutActivationRoute";
import LoginPage from "./shop/components/LoginPage";
import UserOrders from "./shop/components/account/UserOrders";
import GuddenLayout from "./GuddenLayout";
import Home from "./Home";
import { initGA, logPageView } from './Analytics';
import OrderDetails from "./shop/components/account/OrderDetails";
import AccountLayout from "./shop/components/account/AccountLayout";
import AccountDetails from "./shop/components/account/AccountDetails";
import RegisterPage from "./shop/components/RegisterPage";


function App() {
  const [loading, setLoading] = useState(false);
  const { theme } = useBreakpoints();
  function TrackPageViews() {
    const location = useLocation();
  
    useEffect(() => {
      initGA('G-VBHEBBSTBC');
    }, []);
  
    useEffect(() => {
      logPageView();
    }, [location]);
  
    return null;  // Ce composant ne rend rien visuellement.
  }

  return (
    <ChakraProvider theme={theme}> 
      <AuthProvider loading={loading} setLoading={setLoading}>
        <Router>
         <TrackPageViews />
          <div className="App">
            {loading ? (
              <Spinner />
            ) : (
              <Routes>
                <Route
                  path="/"
                  element={
                      <Home />
                  }
                />
                   <Route
                  path="/login"
                  element={
                      <LoginPage />
                  }
                />
                <Route
                  path="/register"
                  element={
                      <RegisterPage />
                  }
                />
                <Route
                  path="/account/"
                  element={
                    <PrivateRoute>
                      <GuddenLayout>
                      <AccountLayout>
                        <AccountDetails />
                      </AccountLayout>
                      </GuddenLayout>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/account/orders"
                  element={
                    <PrivateRoute>
                      <GuddenLayout>
                      <AccountLayout>
                        <UserOrders />
                      </AccountLayout>
                      </GuddenLayout>
                    </PrivateRoute>
                  }
                />
                
                <Route path="/account/orders/:restaurantId/:orderId" element={<PrivateRoute>
                 <GuddenLayout> <AccountLayout><OrderDetails /></AccountLayout></GuddenLayout>
                  </PrivateRoute>} />
                
                <Route path="/:restaurantId/menu" element={<MenuFrontend />} />
                <Route
                  path="/:restaurantId/shop"
                  element={
                    <MainProvider>
                      <ShopActivationRoute />
                    </MainProvider>
                  }
                />

                <Route
                  path="/:restaurantId/shop/checkout"
                  element={
                    <PrivateRoute>
                      <MainProvider>
                        <CheckoutActivationRoute />
                      </MainProvider>
                    </PrivateRoute>
                  }
                />
                

             
              </Routes>
            )}
          </div>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
