import { Link } from "react-router-dom";
import { Flex, Button, Tooltip, Text } from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import UserProfile from "./account/UserProfile";
import { SocialIcon } from 'react-social-icons';
import { useShopContext } from "../context/ShopContext";
import { useEffect, useState } from "react";
import { Timestamp } from "firebase/firestore"; // Assure-toi d'importer Timestamp
import DeliveryModeSwitcher from "./checkout/DeliveryModeSwitcher";

const ShopHeader = ({ styles, logoUrl, logoMenuUrl, restaurantId }) => {
  const { checkAuth, currentUser, loading} = useAuth();
  const { shopSettings } = useShopContext();
  const [isLoading, setIsLoading] = useState(true);

  // Check auth state on component mount
  useEffect(() => {
    const verifyAuth = async () => {
      try {
        await checkAuth(); // Force explicit check of auth state
      } catch (error) {
        console.error("No user authenticated", error);
      } finally {
        setIsLoading(false);
      }
    };

    verifyAuth();
  }, [checkAuth]);

  const temporaryMessage = shopSettings?.general?.temporaryMessage;
  const expirationDate = temporaryMessage?.expirationDate
  ? temporaryMessage.expirationDate instanceof Timestamp
    ? temporaryMessage.expirationDate.toDate()
    : new Date(temporaryMessage.expirationDate)
  : null;

const isMessageActive = expirationDate ? expirationDate >= new Date() : true;
  
  if (isLoading || loading) {
    return <div>Loading...</div>;
  }

  return (
<>
    {isMessageActive && temporaryMessage?.text && (
      <Flex className="temporary-message" w={"100%"} bg={"var(--error)"} py={1} justifyContent={"center"}>
        <Text color={"white"}>{temporaryMessage.text}</Text>
      </Flex>
    )}

    <Flex as="header" className={styles.shopHeader} zIndex={9999999}>
      <div className={styles.container}>
        <Link
          to={`/${restaurantId}/shop`}
          className={styles.logo}
          title="Back to home"
        >
          <img src={logoUrl || logoMenuUrl } alt="Logo" />
        </Link>

        <Flex direction="row" gap={4} alignItems={"center"}>
          
          <DeliveryModeSwitcher />
          
          
          <Flex gap={1}>
            {shopSettings?.contact?.social?.instagram && (
              <Tooltip label="Follow us on Instagram">
                <SocialIcon
                url={`https://instagram.com/${shopSettings.contact.social?.instagram}`}
                style={{ width: "32px", height: "32px" }}
                target="_blank"
              />
              </Tooltip>
            )}
            {shopSettings?.contact?.social?.facebook && (
              <Tooltip label="Follow us on Facebook">
                <SocialIcon
                url={`https://facebook.com/${shopSettings.contact.social?.facebook}`}
                style={{ width: "32px", height: "32px" }}
                target="_blank"
              /></Tooltip>
            )}
            {shopSettings?.contact?.social?.x && (
               <Tooltip label="Follow us on X">
                <SocialIcon
                url={`https://twitter.com/${shopSettings.contact.social?.x}`}
                style={{ width: "32px", height: "32px" }}
                target="_blank"
              /></Tooltip>
            )}
          </Flex>

          {currentUser ? (
            <UserProfile />
          ) : (
            <Button as={Link} to="/login" variant="outline">
              Login
            </Button>
          )}
        </Flex>
      </div>
    </Flex>
</>
  );
};

export default ShopHeader;
