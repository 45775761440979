import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { auth } from "../../../firebaseConfig"; // Assurez-vous d'importer votre configuration Firebase
import { signOut } from "firebase/auth";
import {
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { useAuth } from "../../context/AuthContext";
import { ChevronDown, Power, ReceiptText, User } from "lucide-react";
import { useState } from "react";

const UserProfile = () => {
  const { restaurantId } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const restaurant = restaurantId || sessionStorage.getItem("restaurantId");

  // State to handle image fallback
  const [imageError, setImageError] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      sessionStorage.setItem("prevPath", location.pathname);
      console.log("Storing prevPath in sessionStorage:", location.pathname);
      navigate(`/${restaurant}/shop`);
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <Menu >
      <MenuButton border={"1px solid var(--borders)"} borderRadius="42px" p={1} height="38px" _hover={{ borderColor: "var(--grey300)" }}>
        <Flex
          direction="row"
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"left"}>
          {currentUser?.photoURL && !imageError ? (
              <Image
                src={currentUser.photoURL}
                alt="Profile"
                width="30px"
                height="30px"
                borderRadius={"32px"}
                onError={() => setImageError(true)}
              />
            ) : (
               <Flex w={"30px"} h={"30px"} borderRadius={"32px"} bg={"var(--grey100)"} justifyContent={"center"} alignItems={"center"}><User size={20} color="var(--grey500)" /></Flex>
            )}
          <ChevronDown color="var(--grey700)" size={16} />
        </Flex>
      </MenuButton>

      <MenuList>
        <MenuItem
          icon={<ReceiptText size={16} />}
          justifyContent={"flex-start"}
          gap={0}
          fontWeight={500}
          as={Link}
          to="/account/orders">
          Orders
        </MenuItem>
        <MenuItem
          icon={<User size={16} />}
          justifyContent={"flex-start"}
          gap={0}
          fontWeight={500}
          as={Link}
          to="/account">
          Account details
        </MenuItem>

        <MenuDivider />
        <MenuItem
          icon={<Power size={16} />}
          justifyContent={"flex-start"}
          gap={0}
          fontWeight={500}
          onClick={handleLogout}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserProfile;
