import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from "../../firebaseConfig"; // Assurez-vous que votre configuration Firebase est correctement importée
import { doc, setDoc } from "firebase/firestore";
import {
  Box,
  Flex,
  Stack,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Divider,
  AbsoluteCenter,
} from "@chakra-ui/react";
import logo from "../../img/guddenApp2.svg"; // Remplacez par votre logo si nécessaire
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegisterPage = () => {
    const { restaurantId } = useParams();
const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); // État de chargement pour le bouton
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); // Pour la navigation après l'inscription réussie

  // Fonction pour basculer la visibilité du mot de passe
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  
  const restaurant = restaurantId || sessionStorage.getItem('restaurantId');


if (!restaurant) {
  console.error("Restaurant ID is missing");
  // Gérer le cas où restaurantId est manquant, rediriger ou afficher une erreur
}

  // Fonction pour créer l'utilisateur dans Firestore
  const createUserInFirestore = async (user) => {
    const userRef = doc(db, `restaurants/${restaurant}/customers`, user.uid);
    await setDoc(userRef, {
      uid: user.uid,
      email: user.email,
      firstName: firstName, // Stocke séparément dans Firestore
      lastName: lastName,   // Stocke séparément dans Firestore
      displayName: user.displayName || "",
      photoURL: user.photoURL || "",
      role: "customer",
    });
  };

  // Gérer la soumission du formulaire pour l'enregistrement de l'utilisateur
  const handleRegister = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Les mots de passe ne correspondent pas");
      return;
    }
    setIsLoading(true); // Activer l'état de chargement pour le bouton
  
    try {
      // Créer l'utilisateur avec Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Mettre à jour le displayName dans Firebase Authentication
      await updateProfile(user, {
        displayName: `${firstName} ${lastName}` // Combiner firstName et lastName en displayName
      });
  
      // Stocker les détails de l'utilisateur dans Firestore
      await createUserInFirestore(user);
  
      // Naviguer vers la page du shop après l'inscription
      navigate(`/${restaurant}/shop`);
  
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      toast.error("Échec de l'inscription : " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer
        className="loginToast"
        autoClose={false}
        style={{ maxWidth: "500px !important" }}
        hideProgressBar={true}
        closeOnClick
        theme="colored"
        position="top-center"
      />
      <Flex
        width="100%"
        height={"calc(100vh - 96.55px - 51.5px)"}
        py={16}
        alignItems="center"
        direction={"column"}
        gap={16}
      >
        <Link to="/"><img src={logo} alt="Logo" width={"62px"} /></Link>

        <Flex
          direction="column"
          maxW="360px"
          w={"100%"}
          p={6}
          mx="auto"
          gap={4}
        >
          <h4>Create an account</h4>
          <form onSubmit={handleRegister}>
            <Stack gap={2}>
              <Input
                size="lg"
                variant="filled"
                focusBorderColor="#121212"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First name"
                required
              />
              <Input
                size="lg"
                variant="filled"
                focusBorderColor="#121212"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last name"
                required
              />
              <Input
                size="lg"
                variant="filled"
                focusBorderColor="#121212"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email address"
                required
              />
              <InputGroup size="lg">
                <Input
                  variant="filled"
                  focusBorderColor="#121212"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                />
                <InputRightElement>
                <Button h='1.5rem' size='xs' onClick={togglePasswordVisibility} variant={"outline"}>
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <InputGroup size="lg">
                <Input
                  variant="filled"
                  focusBorderColor="#121212"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm"
                />
              </InputGroup>
              <Button
                size="lg"
                bg="var(--brand)"
                color="white"
                type="submit"
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Create account
              </Button>
            </Stack>
          </form>

          <Box position="relative" padding="6">
            <Divider />
            <AbsoluteCenter bg="white" px="4">
              ou
            </AbsoluteCenter>
          </Box>
          <Stack>
            <Button as={Link} to="/login" variant="link" fontSize="13px !important" mt={2} textDecoration={"underline"}>
              Already have an account ? Log in
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
};

export default RegisterPage;
