import { useShopContext } from "../../context/ShopContext";
import { Box } from "@chakra-ui/react";
const DeliveryModeSwitcher = ({ restaurantId }) => {
  const { styles, shopSettings, deliveryMode, handleDeliveryModeChange } = useShopContext();

    const activeCursorStyle = {
      transition: "all .5s ease",
      left:
        !shopSettings.takeAway.activation || deliveryMode === "pickup"
          ? "4px"
          : "50%", 
    };
    
    const calculateWidth = () => {
      if (shopSettings.takeAway.activation && !shopSettings.delivery.activation) {
        return "calc(100% - 8px)"; // TakeAway is true, Delivery is false
      } else if (!shopSettings.takeAway.activation && shopSettings.delivery.activation) {
        return "calc(100% - 8px)"; // Delivery is true, TakeAway is false
      } else if (shopSettings.takeAway.activation && shopSettings.delivery.activation) {
        return "calc(50% - 4px)"; // Both are true
      }
      return "0"; // Default case or none are active
    };
    

  return (
    <div className={styles.deliveryMode}>
      {shopSettings.takeAway.activation === true && (
        <>
          <input
            type="radio"
            name="deliveryMode"
            value="pickup"
            id="pickup"
            checked={deliveryMode === "pickup"}
            onChange={handleDeliveryModeChange}
          />
          <label htmlFor="pickup">Pickup</label>
        </>
      )}
      {shopSettings.delivery.activation === true && (
        <>
          <input
            type="radio"
            name="deliveryMode"
            value="delivery"
            id="delivery"
            checked={deliveryMode === "delivery"}
            onChange={handleDeliveryModeChange}
          />
          <label htmlFor="delivery">Delivery</label>
        </>
      )}
      <Box
        className={styles.activeCursor}
        style={activeCursorStyle}
        w={calculateWidth()} 
        
      ></Box>
    </div>
  );
};

export default DeliveryModeSwitcher;
