import { Flex, Text, Skeleton } from "@chakra-ui/react";
import NextAvailableSlot from "./NextAvailableSlot";
import Planification from "./Planification";
import SelectSlot from "./SelectSlot";
import useAvailableSlots from "../../hooks/useAvailableSlots";
import { DateTime } from 'luxon';

const SlotsSelection = ({
  restaurantId,
  isPlanningTime,
  deliveryMode,
  handleSlotSelection,
  selectedSlot,
  isNextAvailableSelected,
  scheduleModalIsOpen,
  openScheduleModal,
  closeScheduleModal,
  setIsPlanningTime,
}) => {

  const { availableSlots, nextOpeningDate, isLoading } = useAvailableSlots(deliveryMode);

  return (
    <>
      {isLoading ? (
        <Flex direction={"column"} gap={2}>
      <Skeleton height="58.5px" width="100%" borderRadius={"8px"} />
      <Skeleton height="58.5px" width="100%" borderRadius={"8px"} />
      </Flex>

      ) : availableSlots.length === 0 && nextOpeningDate ? (
        <Flex bg={"#FEFBE8"} borderLeft={"2px solid #FAC515"} borderRadius={"8px"} px={8} py={4}>
          <Text color="var(--grey700)" fontWeight={500}>
          The restaurant will reopen on {nextOpeningDate.setLocale('en').toLocaleString(DateTime.DATE_FULL)}. You can place your order starting the day before.
          </Text>
        </Flex>
      ) : (
        <>
          <Flex
            cursor={"pointer"}
            p="16px"
            className={`option ${isPlanningTime ? "" : "active"}`}
            onClick={() => setIsPlanningTime(false)}>
            <NextAvailableSlot restaurantId={restaurantId} />
          </Flex>
          <Flex
            p="16px"
            cursor={"pointer"}
            className={`option ${isPlanningTime ? "active" : ""}`}
            onClick={() => {
              setIsPlanningTime(true);
              openScheduleModal();
            }}>
            <Planification
              deliveryMode={deliveryMode}
              handleSlotSelection={handleSlotSelection}
              selectedSlot={selectedSlot}
              isNextAvailableSelected={isNextAvailableSelected}
            />
          </Flex>
        </>
      )}

      <SelectSlot
        isOpen={scheduleModalIsOpen}
        onClose={closeScheduleModal}
        availableSlots={availableSlots}
        onSlotSelect={handleSlotSelection}
      />
    </>
  );
};

export default SlotsSelection;
