import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import UserDetails from '../components/checkout/UserDetails';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  
  const [currentUser, setCurrentUser] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(true);

  const EXPIRATION_TIME = 15 * 24 * 60 * 60 * 1000; // 15 days in milliseconds
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {  

      if (userAuth) {
        const restaurant = sessionStorage.getItem('restaurantId');
        const lastLoginTime = localStorage.getItem('lastLoginTime');

        // Check if the user's session has expired (15 days)
        const currentTime = new Date().getTime();
        if (lastLoginTime && currentTime - lastLoginTime > EXPIRATION_TIME) {
          await logout(); // Logout if more than 15 days have passed
          return;
        }

        if (restaurant) {
          const userRef = doc(db, `restaurants/${restaurant}/customers`, userAuth.uid);
          const docSnap = await getDoc(userRef);
  
          if (docSnap.exists()) {
            setCurrentUser({
              uid: userAuth.uid,
              email: userAuth.email,
              firstName: userAuth.firstName,
              lastName: userAuth.lastName,
              displayName: userAuth.displayName,
              photoURL: userAuth.photoURL,
              role: userAuth.role,
              phone: userAuth.phone,
              address: userAuth.address,
              ...docSnap.data(),
            });
          } else {
            setCurrentUser(userAuth);
          }
        }

        // Set or update the last login time in localStorage
        localStorage.setItem('lastLoginTime', currentTime);
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });
  
    return unsubscribe;
  }, []);

  // Function to explicitly check if the user is authenticated
  const checkAuth = async () => {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
        if (userAuth) {
          const restaurant = sessionStorage.getItem('restaurantId');
          if (restaurant) {
            const userRef = doc(db, `restaurants/${restaurant}/customers`, userAuth.uid);
            const docSnap = await getDoc(userRef);
    
            if (docSnap.exists()) {
              setCurrentUser({
                uid: userAuth.uid,
                email: userAuth.email,
                firstName: userAuth.firstName,
                lastName: userAuth.lastName,
                displayName: userAuth.displayName,
                photoURL: userAuth.photoURL,
                role: userAuth.role,
                phone: userAuth.phone,
                address: userAuth.address,
                ...docSnap.data(),
              });
            } else {
              setCurrentUser(userAuth);
            }
            resolve(userAuth);
          }
        } else {
          setCurrentUser(null);
          reject('No user is authenticated');
        }
        unsubscribe();
      });
    });
  };

  const login = (user) => {
    setCurrentUser(user);
    // Store the login time in localStorage
    localStorage.setItem('lastLoginTime', new Date().getTime());
  };

  const logout = async () => {
    try {
      await auth.signOut();
      setCurrentUser(null);
      localStorage.removeItem('lastLoginTime'); // Clear the login time on logout
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const [isUserDetailsOpen, setIsUserDetailsOpen] = useState(false);
  const toggleUserDetailsModal = () => {
    setIsUserDetailsOpen(!isUserDetailsOpen);
  };

  const updateUserDetails = (updatedFields) => {
    setCurrentUser((prev) => ({
      ...prev,
      ...updatedFields,
    }));
  };

  const value = {
    currentUser,
    login,
    logout,
    checkAuth, // Export the checkAuth function
    isAuthenticated: !!currentUser,
    loading,
    isUserDetailsOpen,
    setIsUserDetailsOpen,
    toggleUserDetailsModal,
    updateUserDetails,
    firstName, setFirstName, lastName, setLastName, phone, setPhone, address, setAddress
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
      {!loading && <UserDetails isOpen={isUserDetailsOpen} onClose={toggleUserDetailsModal} currentUser={currentUser} />}
    </AuthContext.Provider>
  );
};
