import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";
import { Link } from "react-router-dom";
import { Image, Flex } from "@chakra-ui/react";
import { Facebook } from "lucide-react";
const Header = ({ restaurantId, logo, styles, defaultLanguage, secondaryLanguages, selectedLanguage, handleLanguageChange }) => {
  
  
return (
    <header>
      <div className={styles.container}>
        <Link to={`/${restaurantId}/menu`} className={styles.logo} id="#logo" title="Back to home">
          {logo && 
            <Image src={logo} alt="Logo" objectFit={"contain"} position={"absolute"} top={0} left={0} height={"100%"} w='100%' />
          }
        </Link>
      
      <div className={styles.right}>

         <LanguageSwitcher 
          selectedLanguage={selectedLanguage}
          defaultLanguage={defaultLanguage}
          secondaryLanguages={secondaryLanguages}
          onLanguageChange={handleLanguageChange} 
          styles={styles}
        />
        


        </div>
      </div>
    </header>
  );
};

export default Header;
