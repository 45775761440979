import React, { useState, useEffect, useRef } from "react";
import useAllergenes from "../../hooks/useAllergenes";
import useTranslations from "../../components/frontend/hooks/useTranslations";
import Modal from "react-modal";
import { useShopContext } from "../context/ShopContext";
import { CirclePlus, CircleMinus } from 'lucide-react';
import { Flex, Text, Heading, Box, Button, Tooltip } from "@chakra-ui/react";

Modal.setAppElement("#root");

const modalStyles = {
  content: {
    height: "100%",
    width: "100%",
    maxWidth: "480px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99",
    width: "100%",
    height: "100%",
  },
};

const ShopItemModal = ({
  restaurantId,
  item,
  isOpen,
  onClose,
  category,
  styles,
  selectedLanguage,
  defaultLanguage,
  quantity,
  setQuantity,
  itemNote,
  setItemNote,
  handleAddToCart,
  selectedVariation,
  setSelectedVariation,
  subTotal,
  setSubTotal,
  selectedExtras,
  setSelectedExtras,
  formatPrice,
  calculateSubTotal,
  isScrolled,
  setIsScrolled,
}) => {
  const { extrasGroups } = useShopContext(restaurantId);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);
  const [canAddToCart, setCanAddToCart] = useState(false); // Start with false since we need validation
  
  const { cartItems, shopSettings} = useShopContext();

  const maxItemsPerOrder = shopSettings?.general?.maxItemsPerOrder || Infinity;
  const totalItemsInCart = cartItems.reduce((total, item) => total + item.quantity, 0);

    useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mobileContentStyles = {
    ...modalStyles.content,
    alignItems: isMobile ? "flex-end" : "center",
  };

  const allergenes = useAllergenes();
  const { translations } = useTranslations();

  const applicableExtrasGroups = extrasGroups.filter(
    (group) =>
      group.applicableToOptions.includes(item.id) ||
      group.applicableToOptions.includes(category.id)
  );

  useEffect(() => {
    if (
      item.type === "variable" &&
      item.variations.length > 0 &&
      !selectedVariation
    ) {
      const firstVariation = item.variations[0];
      setSelectedVariation(firstVariation);
    }
  }, [item, selectedVariation]);

  useEffect(() => {
    if (
      item.type === "variable" &&
      item.variations.length > 0 &&
      !selectedVariation
    ) {
      setSelectedVariation(item.variations[0]);
    }

    setSubTotal(calculateSubTotal());
  }, [quantity, selectedVariation, selectedExtras, item]);

  // Function to validate if selection satisfies min and max conditions
  const validateSelections = (updatedExtras) => {
    let valid = true; // Start with true, then invalidate if any group fails the rules
    
    applicableExtrasGroups.forEach((group) => {
      const selectedExtrasInGroup = updatedExtras.filter((extra) =>
        group.items.some((item) => item.name[selectedLanguage] === extra.name[selectedLanguage])
      );
      
      const { minSelections = 0, maxSelections = 0 } = group;

      // If minSelections > 0, check if we meet the minimum selection requirement
      if (minSelections > 0 && selectedExtrasInGroup.length < minSelections) {
        valid = false; // If fewer than the minimum required extras are selected, invalidate
      }

      // If maxSelections is defined and > 0, ensure the selection doesn't exceed it
      if (maxSelections > 0 && selectedExtrasInGroup.length > maxSelections) {
        valid = false; // If more than the allowed extras are selected, invalidate
      }
    });

    setCanAddToCart(valid); // Update the button's enabled/disabled state
  };

  // This will run once when the modal opens to validate the current state
  useEffect(() => {
    validateSelections(selectedExtras); // Ensure we validate on initial load
  }, [selectedExtras, applicableExtrasGroups]);

  // Handle extra selection with validation for min/max
  const handleExtraChange = (event, extra, groupId) => {
    const { checked, type } = event.target;
    const isRadio = type === "radio";

    setSelectedExtras((prevExtras) => {
      let updatedExtras;
      if (checked) {
        if (isRadio) {
          // For radio, replace any previous selection in this group
          updatedExtras = prevExtras
            .filter(
              (e) =>
                !applicableExtrasGroups
                  .find((group) => group.id === groupId)
                  .items.some((item) => item.name[selectedLanguage] === e.name[selectedLanguage])
            )
            .concat(extra);
        } else {
          // For checkbox, just add the selected extra
          updatedExtras = [...prevExtras, extra];
        }
      } else {
        // Remove the extra if unchecked
        if (!isRadio) {
          updatedExtras = prevExtras.filter(
            (e) => e.name[selectedLanguage] !== extra.name[selectedLanguage]
          );
        }
      }

      // After updating extras, validate selections
      validateSelections(updatedExtras);
      return updatedExtras;
    });
  };

  const incrementQuantity = () => {
    // Check if adding this item would exceed maxItemsPerOrder
    if (totalItemsInCart + quantity < maxItemsPerOrder) {
      setQuantity((q) => q + 1);
    }
  };

  const decrementQuantity = () => {
    setQuantity((q) => (q > 1 ? q - 1 : 1));
  };

  const itemAllergenes = item.allergenes.map(
    (allergeneName) =>
      allergenes.find((allergene) => allergene.name_en === allergeneName) || {
        name: "ask waiter",
        name_en: "ask waiter",
      }
  );

  const handleVariationChange = (variation) => {
    setSelectedVariation(variation);
  };

  const modalInnerRef = useRef(null);
  const modalHeaderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(modalInnerRef.current.scrollTop > 15);
    };

    const modalContentElement = modalInnerRef.current;
    if (modalContentElement) {
      modalContentElement.addEventListener("scroll", handleScroll);

      return () =>
        modalContentElement.removeEventListener("scroll", handleScroll);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      style={{ content: mobileContentStyles, overlay: modalStyles.overlay }}>
      <div className={`${styles.modalContent} ${styles.shop}`}>
        <div ref={modalInnerRef} className={styles.modalInner}>
          <div
            ref={modalHeaderRef}
            className={`${styles.modalHeader} ${isScrolled ? styles.scrolled : ""}`}>
            <span className={styles.close} onClick={onClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                  fill="white"
                />
              </svg>
            </span>
            <div className={styles.itemName}>
              <h3
                style={{
                  color: !item.photo || isScrolled ? "var(--restaurantColor)" : "white",
                }}>
                {item[`name_${selectedLanguage}`] || item[`name_${defaultLanguage}`]}
              </h3>
            </div>
          </div>

          {item.photo && (
            <div className={styles.itemImage}>
              <img
                src={item.photo}
                alt={item[`name_${selectedLanguage}`] || item[`name_${defaultLanguage}`]}
              />
            </div>
          )}

          <div className={styles.itemInfo}>
            {item[`description_${selectedLanguage}`] && (
              <>
                <div className={styles.description}>
                  <h6>Description</h6>
                  <p>{item[`description_${selectedLanguage}`] || item[`description_${defaultLanguage}`]}</p>
                </div>
                <div className={styles.separator}></div>
              </>
            )}
            {item.tags && item.tags.length > 0 && (
              <>
                <div className={styles.tags}>
                  <>
                    {item[`note_${defaultLanguage}`] && (
                      <div className={styles.note}>
                        {item[`note_${selectedLanguage}`] || item[`note_${defaultLanguage}`]}
                      </div>
                    )}

                    {item.tags.map((tag, index) => (
                      <span key={index} className={styles.tag}>
                        {tag.label}
                      </span>
                    ))}
                  </>
                </div>
                <div className={styles.separator}></div>
              </>
            )}

            {!itemAllergenes.some(
              (allergene) => allergene.name === "ask waiter"
            ) && (
              <>
                <div className={styles.allergenes}>
                  <h6>{translations.allergens[selectedLanguage]}</h6>
                  {itemAllergenes.length > 0 ? (
                    <div className={styles.allergenesList}>
                      {itemAllergenes.map((allergene) => (
                        <div key={allergene.id} className={styles.allergene}>
                          <img
                            src={allergene.colorIcon}
                            className={styles.icon}
                            alt={allergene[`name_${selectedLanguage}`] || allergene.name_en}
                          />
                          <span>{allergene[`name_${selectedLanguage}`] || allergene.name_en}</span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>{translations.noAllergens[selectedLanguage]}</p>
                  )}
                </div>
                <div className={styles.separator}></div>
              </>
            )}

            {item.type === "variable" && item.variations.length > 0 && (
              <>
                <div className={styles.options}>
                  <h6>
                    Select an option<span>Mandatory</span>
                  </h6>
                  <div className={styles.optionsList}>
                    {item.variations.map((variation, index) => (
                      <label key={index} htmlFor={`variation-${index}`} className={styles.option}>
                        <div>
                          <input
                            type="radio"
                            name="variation"
                            id={`variation-${index}`}
                            value={variation.name}
                            checked={selectedVariation && selectedVariation.name === variation.name}
                            onChange={() => handleVariationChange(variation)}
                          />
                          <div className={styles.optionName}>{variation.name}</div>
                        </div>
                        <span className={styles.optionPrice}>{variation.price}€</span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className={styles.separator}></div>
              </>
            )}

            {applicableExtrasGroups.map((group) => {
              const selectedExtrasInGroup = selectedExtras.filter(extra =>
                group.items.some(item => item.name[selectedLanguage] === extra.name[selectedLanguage])
              );

              const maxReached = selectedExtrasInGroup.length >= group.maxSelections && group.maxSelections > 0;

              return (
                <React.Fragment key={group.id}>
                  <div className={styles.options}>
                    <Flex alignItems={'baseline'} gap={1}>
                      <Heading as="h6" textTransform="capitalize">
                        {group.name[selectedLanguage]}
                      </Heading>
                      <Flex gap={1} fontSize={"11"}>
                        <Text>{group.minSelections > 0 && `Min.: ${group.minSelections}`}</Text>
                        <Text>{group.maxSelections > 0 && `Max.: ${group.maxSelections}`}</Text>
                      </Flex>
                    </Flex>
                    <div className={styles.optionsList}>
                      {group.items.map((extra) => (
                        <label key={extra.name[selectedLanguage]} className={styles.option}>
                          <div>
                            <input
                              type={group.selectionType === "unique" ? "radio" : "checkbox"}
                              name={group.selectionType === "unique" ? group.name[selectedLanguage] : extra.name[selectedLanguage]}
                              checked={selectedExtras.some((e) => e.name[selectedLanguage] === extra.name[selectedLanguage])}
                              onChange={(event) => handleExtraChange(event, extra, group.id)}
                              disabled={!selectedExtras.some((e) => e.name[selectedLanguage] === extra.name[selectedLanguage]) && maxReached} // Disable if max is reached
                            />
                            <div className={styles.optionName}>{extra.name[selectedLanguage]}</div>
                          </div>
                          <span className={styles.optionPrice}>
                            {extra.price === 0 || extra.price === "0" ? "Free" : `${formatPrice(extra.price)}€`}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className={styles.separator}></div>
                </React.Fragment>
              );
            })}

            <div className={styles.orderNote}>
              <h6>Note</h6>
              <textarea
                placeholder="Add a note..."
                value={itemNote}
                onChange={(e) => setItemNote(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className={styles.addToCart}>
          <div className={styles.qty}>
            <CircleMinus size={32} color="var(--restaurantColor)" onClick={decrementQuantity} />
            <span>{quantity}</span>
                      
                      <Tooltip 
                        label={`You cannot add more than ${maxItemsPerOrder} items to your order.`}
                        isDisabled={totalItemsInCart + quantity < maxItemsPerOrder} // Only show if exceeding max items
                        p={3} fontSize={15} borderRadius={4}>
                       
                        <CirclePlus 
                        size={32} 
                        color="var(--restaurantColor)" 
                        onClick={incrementQuantity} 
                        // Disable the button if the max number of items is reached
                        disabled={totalItemsInCart + quantity >= maxItemsPerOrder} 
                        style={{ cursor: totalItemsInCart + quantity >= maxItemsPerOrder ? 'not-allowed' : 'pointer', opacity: totalItemsInCart + quantity >= maxItemsPerOrder ? '0.24' : '1' }}  // Add disabled style
                    /> 
                    </Tooltip>
          </div>
          <Button
            size="lg"
            w="100%"
            bg="var(--restaurantColor)"
            _hover={{ bg: "var(--restaurantColor)" }}
            color="white"
            onClick={handleAddToCart}
            disabled={!canAddToCart} // Disable button if the validation fails
          >
            <Box className={styles.bgHover}></Box>
            <Box zIndex={2}>
              Add {quantity} for {subTotal}€
            </Box>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShopItemModal;
