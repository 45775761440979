import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { useShopContext } from '../../context/ShopContext';
import { Flex, Input } from "@chakra-ui/react";

const AddressAutoComplete = () => {
  const {
    styles,
    isDeliveryZoneValid,
    onPlaceSelected,
    isAddressSelected,
    setIsAddressSelected,
    checkAddressEligibility
  } = useShopContext();

  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);



  // Eviter une boucle infinie en vérifiant si l'adresse a déjà été vérifiée
  const [addressChecked, setAddressChecked] = useState(false);

  
  useEffect(() => {
    const savedAddress = localStorage.getItem('userAddress');
    if (savedAddress && !addressChecked) {
      setAddress(savedAddress);
      setIsAddressSelected(true);
      setLoading(true);
      checkAddressEligibility(savedAddress).finally(() => setLoading(false));  // Désactiver l'effet de chargement après la vérification
      setAddressChecked(true); 
    }
  }, [addressChecked, setIsAddressSelected, checkAddressEligibility]);

  const onLoad = (autocomplete) => {
    autocomplete.setComponentRestrictions({ country: ["lu"] });
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place && place.geometry) {
        const selectedAddress = place.formatted_address;
        onPlaceSelected(place);
        setAddress(selectedAddress);
        setIsAddressSelected(true);
        localStorage.setItem('userAddress', selectedAddress);
        checkAddressEligibility(selectedAddress);
      } else {
        console.log('No valid address selected.');
        setIsAddressSelected(false);
      }
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    console.log('New address:', newAddress);

    if (!newAddress.trim()) {
      setIsAddressSelected(false);
      localStorage.removeItem('userAddress');
    }
  };

  return (
    <Flex flex="1" direction={"column"} gap={2}>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <Input
          size="lg"
          variant="filled"
          focusBorderColor="#121212"
          type="text"
          value={address}
          placeholder="Enter your address"
          onChange={handleAddressChange}
        />
      </Autocomplete>
      {isAddressSelected ? (
      isDeliveryZoneValid ? (
        <div style={{ color: 'green' }}>
          This address is eligible for delivery.
        </div>
      ) : (
        <div className={styles.errorMessage} style={{ maxWidth: '100%', textAlign: 'left' }}>
          Apologies, but we are unable to deliver to this address.
          Please provide an alternative address or opt for pickup.
        </div>
      )
    ) : null} {/* Affiche rien si l'adresse n'est pas sélectionnée */}
    </Flex>
  );
};

export default AddressAutoComplete;
