import { useEffect, useState } from 'react';
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from '../firebaseConfig';

const useAllergenes = () => {
  
  const [allergenes, setAllergenes] = useState([]);

  useEffect(() => {

    const fetchAllergenes = async () => {
      const querySnapshot = await getDocs(query(collection(db, "allergenes"), orderBy("order")));
      const allergenesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllergenes(allergenesData);
    };

    fetchAllergenes();
  }, []);

  return allergenes;
};

export default useAllergenes;