import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const { restaurantId } = useParams();

  // Sauvegarder l'URL complète incluant pathname et query params
  const fullPath = location.pathname + location.search;

  // Si restaurantId est dans l'URL, le sauvegarder aussi dans sessionStorage
  if (restaurantId) {
    sessionStorage.setItem('restaurantId', restaurantId);
  }

  if (!isAuthenticated) {
    sessionStorage.setItem('prevPath', fullPath);
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default PrivateRoute;
