import React, { useState, useEffect } from "react";
import ShopItemModal from "./ShopItemModal";
import ShopSubCategoryMenu from "./ShopSubCategoryMenu";
import useTranslations from "../../components/frontend/hooks/useTranslations";
import Cart from "./cart/Cart";
import CartModal from "./cart/CartModal";
import { useShopContext } from "../context/ShopContext";
import { Flex, Grid } from "@chakra-ui/react";
import { ListFilter, X } from "lucide-react";
import SkeletonLayout from "./SkeletonLayout";

const ShopList = ({
  restaurantId,
  subCategories,
  menuItems,
  styles,
  tags,
  cartUpdated,
  setCartUpdated,
  addItemToCart,
  cartItems
}) => {
  const {
    defaultLanguage,
    allLanguages,
    selectedLanguage,
    selectedItem,
    setSelectedItem,
    selectedVariation,
    setSelectedVariation,
    selectedExtras,
    setSelectedExtras,
    calculateSubTotal,
    subTotal,
    setSubTotal,
    initialPrice,
    initialVariation,
    quantity,
    setQuantity,
    formatPrice,
    totalWithTip,
    isLoading,
    deliveryMode
  } = useShopContext(restaurantId);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const { translations } = useTranslations();
  const [filter, setFilter] = useState(null);

  const [itemNote, setItemNote] = useState("");
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleCartModal = () => setIsCartModalOpen(!isCartModalOpen);

  // Filtrer les items en fonction du filtre sélectionné
  const filteredItems = menuItems.filter((item) => {
    const hasShopDisplay = item.display && item.display.includes("shop");
    const matchesTagFilter = filter
      ? item.tags &&
        Array.isArray(item.tags) &&
        item.tags.some((tag) => tag.value === filter)
      : true;
    return hasShopDisplay && matchesTagFilter;
  });

  // Extraire les tags uniques des items filtrés
  const uniqueTags = new Set();
  filteredItems.forEach((item) => {
    if (item.tags && Array.isArray(item.tags)) {
      item.tags.forEach((tag) => uniqueTags.add(tag.value));
    }
  });

  // Générer les boutons de tag pour le filtre
  const tagButtons = Array.from(uniqueTags).map((tagID) => {
    const correspondingTag = tags.find((t) => t.id === tagID);
    let tagName = "Unknown";
    if (correspondingTag) {
      tagName = correspondingTag[`name_${selectedLanguage}`] || correspondingTag[`name_${defaultLanguage}`] || "Unknown";
    }

    return (
      <Flex
        direction="row"
        className={`${styles.filter} ${filter === tagID ? "active" : ""}`}
        key={tagID}
        onClick={() => setFilter(filter === tagID ? null : tagID)}>
        <span>{tagName}</span>
        {filter === tagID && <X size={16} />}
      </Flex>
    );
  });

  const classNamesMapping = {
    cards: styles.cards,
  };

  const openModal = (item, category) => {
    setSelectedItem(item);
    setCurrentCategory(category);
    document.body.classList.add("no-scroll");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setQuantity(1);
    setIsScrolled(false);
    setItemNote("");
    setSelectedVariation("");
    setSelectedExtras([]);
    document.body.classList.remove("no-scroll");
    setIsModalOpen(false);
  };

  const itemsByCategory = menuItems.reduce((acc, item) => {
    const { categorieId } = item;
    if (!acc[categorieId]) {
      acc[categorieId] = [];
    }
    acc[categorieId].push(item);
    return acc;
  }, {});

  const handleAddToCart = async () => {
    const cartItem = {
      itemId: selectedItem.id,
      quantity: quantity,
      price: selectedItem.price || 0,
      vat: selectedItem.vat,
    };

    if (selectedItem.photo) {
      cartItem.photo = selectedItem.photo;
    }

    if (itemNote && itemNote.trim() !== "") {
      cartItem.note = itemNote.trim();
    }

    if (selectedVariation) {
      cartItem.price = selectedVariation.price;
      cartItem.variation = {
        name: selectedVariation.name,
      };
    }

    if (selectedExtras && selectedExtras.length > 0) {
      cartItem.extras = selectedExtras.map((extra) => ({
        name: extra.name,
        price: extra.price,
      }));
      cartItem.price = calculateSubTotal();
    }

    // Gérer les noms internationaux
    allLanguages.forEach((lang) => {
      const nameKey = `name_${lang}`;
      if (selectedItem[nameKey]) {
        cartItem[nameKey] = selectedItem[nameKey];
      }
    });
    await addItemToCart(cartItem, allLanguages);
    setCartUpdated(!cartUpdated);
    closeModal();
  };


  return (
    <>
      <Flex as="main" className={styles.wrapper} flex={1}>
        <ShopSubCategoryMenu
          subCategories={subCategories.filter((cat) => !cat.isUncategorized)}
          itemsByCategory={itemsByCategory}
          styles={styles}
          defaultLanguage={defaultLanguage}
          selectedLanguage={selectedLanguage}
        />

        <Flex
          w="100%"
          justifyContent="center"
          background="var(--grey100)"
          flex={1}
          p={{ desktop: "48px 0", mobile: "24px 0" }}>
          
          <Flex
            direction="column"
            p={{ desktop: "0 48px", mobile: "0 24px" }}
            maxW="1920px"
            flex={1}
            gap={{ desktop: "48px", mobile: "24px" }}>
            
            
            
            {isLoading ? (
              <SkeletonLayout />
            ) : (
              <Flex direction={{desktop: "row", mobile: "column"}} gap={12}>
                <Flex direction="column" className={styles.categories} gap={12}>
                {tagButtons && tagButtons.length > 0 && (
              <Flex direction="row" gap={4} className={styles.filtersWrapper}>
                <Flex gap={1} className={styles.button}>
                  <ListFilter size={20} />
                  {translations.filters[selectedLanguage]}
                </Flex>
                <Flex gap={2} className={styles.filters}>
                  {tagButtons}
                </Flex>
              </Flex>
            )}

                  {subCategories
                    .filter((cat) => cat.id !== "uncategorized")
                    .map((category) => {
                      const categoryItems = filteredItems.filter(
                        (item) => item.categorieId === category.id
                      );

                      const categoryStyleClass =
                        category.style &&
                        classNamesMapping[category.style.toLowerCase()]
                          ? classNamesMapping[category.style.toLowerCase()]
                          : "";

                      if (!categoryItems.length) return null;
                      return (
                        <Flex
                          direction="column"
                          gap={6}
                          key={category.id}
                          id={category.id}
                          className={`${styles.category} ${styles.anchor}`}>
                          <h2>
                            {category[`name_${selectedLanguage}`] ||
                              category[`name_${defaultLanguage}`]}
                            <div className={styles.categoryPrice}>
                              {category.formula && category.formulaPrice && (
                                <>
                                  {category.formulaPrice}
                                  <span>€</span>
                                </>
                              )}
                            </div>
                          </h2>

                          <Grid
                            className={`${styles.items} ${categoryStyleClass}`}>
                            {categoryItems
                              .filter(
                                (item) =>
                                  item.vat !== undefined && item.vat !== null
                              )
                              .map((item) => (
                                <Flex
                                  className={styles.item}
                                  key={item.id}
                                  onClick={() => openModal(item, category)}
                                  cursor="pointer"
                                  transition="all .35s ease"
                                  _hover={{
                                    transform: "scale(1.02)"
                                  }}
                                  >
                                  <div className={styles.itemInfo}>
                                    <div className={styles.itemName}>
                                      {item[`name_${selectedLanguage}`] ||
                                        item[`name_${defaultLanguage}`]}
                                    </div>

                                    <div className={styles.itemDescription}>
                                      {item[`description_${defaultLanguage}`] && (
                                        <>
                                          {item[
                                            `description_${selectedLanguage}`
                                          ] ||
                                            item[
                                              `description_${defaultLanguage}`
                                            ]}
                                        </>
                                      )}
                                    </div>

                                    <div className={styles.priceRow}>
                                      <div className={styles.priceInfo}>
                                        {item.type === "variable" &&
                                        item.variations.length > 0 ? (
                                          <span className={styles.itemPrice}>
                                            From{" "}
                                            {Math.min(
                                              ...item.variations.map(
                                                (variation) =>
                                                  parseFloat(
                                                    variation.price.replace(
                                                      ",",
                                                      "."
                                                    )
                                                  )
                                              )
                                            ).toLocaleString("fr-FR", {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                            €
                                          </span>
                                        ) : (
                                          <span className={styles.itemPrice}>
                                            {item.price}€
                                          </span>
                                        )}
                                      </div>

                                      {item.tags && item.tags.length > 0 && (
                                        <div className={styles.tags}>
                                          <>
                                            {category.style !== "Cards" &&
                                              (item[`note_${selectedLanguage}`] ||
                                                item[
                                                  `note_${defaultLanguage}`
                                                ]) && (
                                                <div className={styles.note}>
                                                  {item[
                                                    `note_${selectedLanguage}`
                                                  ] ||
                                                    item[
                                                      `note_${defaultLanguage}`
                                                    ]}
                                                </div>
                                              )}
                                            {item.tags
                                              .slice(0, 1)
                                              .map((tag, index) => (
                                                <span
                                                  key={index}
                                                  className={styles.tag}>
                                                  {tag.label}
                                                </span>
                                              ))}
                                            {item.tags.length > 1 && (
                                              <span className={styles.tag}>
                                                +{item.tags.length - 1}
                                              </span>
                                            )}
                                          </>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {item.photo && (
                                    <div className={styles.itemImage}>
                                      <img
                                        src={item.photo}
                                        alt={
                                          item[`name_${selectedLanguage}`] ||
                                          item[`name_${defaultLanguage}`]
                                        }
                                      />
                                    </div>
                                  )}
                                </Flex>
                              ))}
                          </Grid>
                        </Flex>
                      );
                    })}
                </Flex>
                
                  <Cart
                    restaurantId={restaurantId}
                    defaultLanguage={defaultLanguage}
                    selectedLanguage={selectedLanguage}
                  />
         
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>

      {isModalOpen && selectedItem && (
        <ShopItemModal
          restaurantId={restaurantId}
          item={selectedItem}
          isOpen={openModal}
          onClose={closeModal}
          category={currentCategory}
          styles={styles}
          selectedLanguage={selectedLanguage}
          defaultLanguage={defaultLanguage}
          allLanguages={allLanguages}
          addItemToCart={addItemToCart}
          cartUpdated={cartUpdated}
          setCartUpdated={setCartUpdated}
          quantity={quantity}
          setQuantity={setQuantity}
          itemNote={itemNote}
          setItemNote={setItemNote}
          handleAddToCart={handleAddToCart}
          selectedVariation={selectedVariation}
          setSelectedVariation={setSelectedVariation}
          selectedExtras={selectedExtras}
          setSelectedExtras={setSelectedExtras}
          subTotal={subTotal}
          setSubTotal={setSubTotal}
          initialPrice={initialPrice}
          initialVariation={initialVariation}
          calculateSubTotal={calculateSubTotal}
          formatPrice={formatPrice}
          isScrolled={isScrolled}
          setIsScrolled={setIsScrolled}
        />
      )}

      {cartItems.length > 0 && (
        <button
          className={`btn ${styles.cartButton} ${styles.primary}`}
          size={"md"}
          onClick={toggleCartModal}>
          Cart {cartItems.length > 0 && <span>{totalWithTip()}€</span>}
        </button>
      )}

      {isCartModalOpen && (
        <CartModal
          isOpen={toggleCartModal}
          onClose={toggleCartModal}
          styles={styles}
          restaurantId={restaurantId}
        />
      )}
    </>
  );
};

export default ShopList;
